/*
    Layout
    - Constraint
 */

// Constraint
.constrain-width {
    max-width: rem($constrain-width);
    margin-right: auto;
    margin-left: auto;

    // Types
    &:not(.no-pad) {
        padding-right: $padding-height-sm;
        padding-left: $padding-height-sm;

        @media (--from-small-screen-w) {
            padding-right: $padding-height;
            padding-left: $padding-height;
        }
    }


    // Sizes
    &.medium {
        max-width: rem($constrain-width-md);
    }

    &.large {
        max-width: rem($constrain-width-lg);
    }


    // Specific
    &.content {
        max-width: rem($constrain-width-content);
    }

    /* Major project specific styles */

    // Make space for left nav on desktop screen
    &.with-left-nav {
        @media (--from-normal-screen-w) {
            padding-left: $padding-left-nav;
        }

        // Used for specific content blocks to escape constrain width
        .full-width-block {
            @media (--from-normal-screen-w) {
                margin-left: -$padding-left-nav;
                margin-right: -$padding-width;
            }
        }
    }

    // Used for specific content blocks to escape constrain width
    .full-width-block {
        margin-right: -$padding-height-sm;
        margin-left: -$padding-height-sm;

        @media (--from-small-screen-w) {
            margin-right: -$padding-height;
            margin-left: -$padding-height;
        }
    }
}

// page header
.article-header {
    margin-bottom: $padding-height-sm;

    @media (--from-medium-screen-w) {
        margin-bottom: $padding-height-md;
    }
}
