/*
    Large thumbnail block
*/
.large-thumbnail-block {

    .thumbnail-section {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;

        @media screen and (max-width: 880px) {
            flex-direction: column;
        }
    }

    .thumbnail-section-item {
        flex-shrink: 0;
        width: rem(500);
        max-width: 100%;
        margin: 0 rem(80) rem(80) 0;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: 1280px) {
            width: rem(400);
        }

        @media screen and (max-width: 1065px) {
            width: rem(360);
        }

        @media screen and (max-width: 880px) {
            width: rem(400);
            margin: 0 0 rem(40);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $color-black;
            opacity: 0.6;
            transition: all 0.2s ease;
        }

        &:hover {
            &::before {
                opacity: 0.8;
            }

            .cta-text {
                color: $color-primary;
            }
        }

        &:nth-child(even) {
            margin-right: 0;
            position: relative;
            top: rem(80);

            @media screen and (max-width: 880px) {
                top: 0;
            }
        }

        > img {
            display: block;
            width: 100%;
            z-index: $layer-thumbnail-image;
            min-height: rem(340);
            max-height: rem(600);
            object-fit: cover;
        }

        &-content {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            color: $color-white;
            padding: rem(50);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width: 1280px) {
                padding: rem(30);
            }

            @media screen and (max-width: 880px) {
                padding: rem(30) rem(20);
            }

            .text-wrapper {
                max-height: 90%;
                overflow: hidden;
            }

            .title {
                text-transform: unset;
                font-size: rem(32);
                line-height: 1.2em;

                @media screen and (max-width: 1280px) {
                    font-size: rem(25);
                }
            }

            .description {
                font-size: rem(20);
                line-height: 1.5em;

                @media screen and (max-width: 1280px) {
                    font-size: rem(18);
                }
            }
        }

        .cta-text {
            color: inherit;
            width: 100%;
            display: flex;
            justify-content: flex-start;

            h3 {
                margin-bottom: 0;
                font-size: rem(25);

                @media screen and (max-width: 880px) {
                    font-size: rem(22);
                }
            }

            .icon {
                flex-basis: 50px;
            }
        }
    }
}