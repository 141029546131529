/*
    Navigation Bar
 */
.navigation-bar {
    display: flex;
    align-items: center;
    flex-grow: 1;

    transition:
        opacity 0.2s ease-in-out 0.1s,
        transform 0.2s ease-in-out 0.1s; // Add delay

    @media (--from-navigation-breakpoint) {
        justify-content: flex-end;
    }


    // Elements
    &-inner {
        @media (--to-navigation-breakpoint) {
            position: fixed;
            top: 0;
            left: 0;
            width: $width-left-nav; 
            height: 100vh;
            overflow: hidden;
            box-shadow: rgb(0 0 0 / 30%) 0 2px 7px 1px inset;
            ::-webkit-scrollbar {
                opacity: 0;
                width: 0;
                display: none;
                background-color: transparent;
            }

            color: $color-light;
            background-color: $color-primary;

            transform: translateX(-100%);
            transition: transform $overlay-transition-close;
        }

        @media (--from-navigation-breakpoint) {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
}
