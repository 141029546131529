.options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    padding: rem(20);
    box-shadow: 0 rem(3) rem(4) $color-box-shadow;
    background-color: $color-tint-3;
    z-index: $layer-dropdown-option;
    
    ul {
        flex: 1 0 33%;
        list-style-type: none;
        max-width: 60%;
        margin: 0;
        padding: 0;
    }

    li {
        padding-right: rem(10);
        margin-bottom: rem(10);
    }

    // Date selector for filter-dropdown-with-date component
    .date-options {
        display: none;
        flex: 1 0 67%;
        max-width: 60%;

        @media (--from-medium-screen-w) {
            display: block;
        }

        .date-picker {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            min-width: rem(125);
            background-color: $color-light;
            margin-bottom: rem(20);
            border: 1px solid $color-tint-1;

            .input {
                border: none;
            }

            label {
                color: $color-primary;
                margin-right: rem(15);
            }
        }

        .button {
            width: 100%;
        }
    }

    .controls {
        width: calc(100% + rem(40));
        padding: rem(10) rem(20);
        margin-right: -rem(20);
        margin-bottom: -rem(20);
        margin-left: -rem(20);
        background-color: $color-tint-2;
    }

    &.is-hidden {
        visibility: hidden;
    }

    &.advanced-search-dropdown, &.archive-options, &.idea-options {
        flex-direction: column;
        justify-content: space-between;
        border-top: rem(1) solid $color-tint-1;
        width: 100%;
    
        @media (--from-medium-screen-w) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    
        h6 {
            margin: rem(15) 0;
        }
    
        .field-group {
            display: flex;
            margin-bottom: rem(10);
    
            @media (--from-small-screen-w) {
                margin-bottom: 0;
            }
    
            label {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    
        .scrollable {
            max-height: 235px;
            overflow-y: auto;
        }
    
        .inline-dates {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            align-items: center;
            max-width: 90%;
            margin-bottom: rem(15);
    
            .input {
                flex: 1 1 0;
            }
    
            .highlight {
                padding: 0 rem(5);
            }
        }
    
        .column {
            display: block;
        
            @media (--from-medium-screen-w) {
                width: 25%;
            }

            &.hide-to-3 {
                display: none;
        
                @media (--from-medium-screen-w) {
                    display: block;
                }
            }
        }
    }
}