/*
    Hero artwork block
*/
.hero-artwork-block {
    padding: rem(50) rem(20);

    @media (--from-small-screen-w) {
        padding: rem(50);
    }

    @media (--from-normal-screen-w) {
        padding: rem(50) rem(130) rem(100) rem(150);
    }

    .block-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media (--from-normal-screen-w) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    .text-section {
        order: 2;
        width: rem(200);

        @media (--from-normal-screen-w) {
            order: 0;
            flex-basis: rem(200);
            flex-shrink: 0;
            margin-right: rem(50);
        }

        .title {
            font-weight: $weight-medium;
            margin-bottom: rem(5);
        }

        hr {
            color: inherit;
        }
    }

    .image-section {
        max-width: 100%;
        margin-bottom: rem(50);
        position: relative;

        @media (--from-normal-screen-w) {
            overflow: hidden;
            margin: 0;
        }
    
        > img {
            display: block;
            max-width: 100%;
            max-height: rem(500);

            @media (--from-normal-screen-w) {
                width: auto;
                max-width: unset;
                height: rem(400);
                max-height: rem(400);

            }

            @media (--from-large-screen-w) {
                height: rem(500);
                max-height: rem(500);
            }

            @media (--from-x-large-screen-w) {
                height: rem(650);
                max-height: rem(650);
            }

        }

        .img-magnifier-glass {
            position: absolute;
            border-radius: 50%;
            cursor: none;
            visibility: hidden;
        }
    }
}
