/*
    Page Header
 */
.page-header {
    

    position: relative;
    display: flex;
    flex-direction: column;
    @media (--from-small-screen-w) {
        flex-direction: row;
    }
    border-bottom: 1px solid $color-light-grey;
    margin-top: rem($vertical-spacing-medium);
    margin-bottom: rem($vertical-spacing-medium);

    &-image {
        object-fit: cover;
        min-height: rem(240);
        max-width: 100%;
    }

    &-container {
        margin-bottom: rem($vertical-spacing-medium);
        &-image {
            padding: rem(15);
            @media (--from-small-screen-w) {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 0 0 rem(20) rem(30);
            }
        }

        > h1 {
            line-height: 1.1em;
            margin-bottom: rem(8);
        }
    }

    &-title {
        font-size: rem(26);
        @media (--from-small-screen-w) {
            font-size: rem(62);
        }
    }
    
    .image-title {
        font-weight: 700;
    }

    &-subtitle {
        color: $color-black;
        margin-bottom: 0;
        font-size: rem(16);
        @media (--from-small-screen-w) {
            font-size: rem(26);
        }
    }

    &-font-white {
        color: $color-white;
    }
    
    // background color
    &-black {
        background-color: $color-black;
    }
    &-red {
        background-color: $color-red;
    }
}

