.advanced-collection-search-page {
    .algorithm-right {
        display: flex;
        align-items: flex-end;
        padding: rem(25) rem(15) rem(10);
    
        @media (--from-small-screen-w) {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 0 rem(40) rem(25);
        }
    
        > .text-block {
            font-size: rem(26);
            font-weight : 700;
            line-height: 0.9;
            @media (--from-small-screen-w) {
                font-size: rem(64);
            }
            text-transform: uppercase;
            font-kerning : normal;
            letter-spacing: rem(1.5);
            white-space: pre;
            margin-bottom: 0;
            text-align: start;
        }
    
        > .white {
            color: $color-white;
        }
    
        > .red { 
            color: $color-red;
        }
    
        > .text-right {
            text-align: right;
        }
    }

    .search-form {
        .title h2 {
            padding-bottom: rem(10);
            border-bottom: rem(1) solid $color-tint-1;
        }

        .form-container {
            margin-top: rem(40);
            padding: 0 rem(14);
            width: 100%;

            @media (--from-small-screen-w) {
                width: 66.7%;
            }
        }


        .field-group {
            display: flex;
            flex-direction: column;

            .dropdown {
                cursor: pointer;
                .arrow {
                    color: white;
                }
            }

            input {
                padding: rem(4) rem(12);
                height: rem(36);
                border: rem(1) solid $color-tint-1;
                border-radius: 2px;

                &:focus {
                    outline: 0;
                }
            }
            .message {
                width: 100%;
                padding-top: rem(6);
            }

            &.primary {
                margin-bottom: rem(20);
            }

            &.secondary {
                flex-flow: row wrap;

                .inline {
                    display: inline-flex;
                    flex-direction: row;
                    max-width: rem(170);
                    margin-right: rem(10);
                    margin-bottom: rem(5);
                    align-items: center;
                    flex-basis: 50%;
                    flex-grow: 1;

                    &.checkbox {
                        max-width: 100%;
                    }
                }

                .label {
                    margin-bottom: 0;
                    margin-right: rem(10);
                }

                input {
                    flex: 0 1 auto;
                    flex-basis: rem(115);
                }
            }

            .tertiary {
                padding-top: rem(10);
            }
        }
    }

    .label {
        font-weight: $weight-regular;
        margin-bottom: rem(10);
    }

    .checkbox span {
        color: $color-secondary;
    }
}
