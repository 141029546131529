/*
    Debug
*/
.placeholder-component {
    padding: rem(20);
    margin-bottom: rem(10);

    background-color: rgb(245, 245, 245);

    pre {
        overflow-x: scroll;
    }
}
