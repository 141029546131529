/*
    List
    - Ordered
    - Unordered
    - List item
*/
%unlist {
    padding-left: 0;
    margin: 0;

    list-style-type: none;
}

.list {
    @extend %unlist;
}
