/*
    Button
*/
button {
    display: inline-flex;
    padding: 0;

    line-height: 1;
    white-space: nowrap;

    background: none;
    border: none;
    border-radius: rem(2);

    transition: color $base-transition-speed $base-transition-easing;

    appearance: none;

    &:hover {
        color: $color-primary;

        outline: none;
    }
}

.button {
    @extend %box-rounded;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: rem(11) rem(17);

    font-size: rem(16);
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    cursor: pointer;

    border: rem(1) solid transparent;

    transition: color $base-transition-speed $base-transition-easing, border $base-transition-speed $base-transition-easing, background-color $base-transition-speed $base-transition-easing;

    &:hover {
        text-decoration: none;
    }


    // Types
    &.default {
        color: $color-primary;
        font-weight: $weight-light;

        border-color: currentColor;

        &:hover {
            color: $color-secondary;

            border-color: currentColor;
        }
    }

    // Types
    &.default.reverse {
        color: $color-secondary;
        font-weight: $weight-light;

        border-color: currentColor;

        &:hover {
            color: $color-primary;

            border-color: currentColor;
        }
    }
    

    &.default.inverse {
        color: $color-light;

        @media print {
            color: $color-light;

            border-color: $color-light;
        }

        &:hover {
            @media (--from-small-screen-w) {
                color: $color-primary;

                background-color: $color-light;
                border-color: $color-light;
            }
        }
    }

    &.primary {
        color: $color-light;
        font-weight: $weight-regular;

        background-color: $color-primary;

        @media print {
            color: $color-light;

            background-color: transparent;
            border-color: $color-light;
        }

        &:hover {
            background-color: $color-secondary;
        }
    }

    &.primary.inverse {
        color: $color-primary;

        background-color: $color-light;

        &:hover {
           @media (--from-small-screen-w) {
                color: $color-light;

                background-color: $color-secondary;
            }
        }
    }

    &[disabled], &[disabled]:hover {
        color: $color-light;
        font-weight: $weight-regular;

        cursor: default;
        background-color: $color-tint-1;

        @media print {
            color: $color-secondary;

            background-color: transparent;
            border-color: $color-tint-1;
        }
    }


    // Sizes
    &.small {
        height: rem(36);
        padding: rem(10) rem(14);

        font-size: rem(14);
    }

    &.tiny {
        height: rem(30);
        padding: rem(8) rem(9);

        font-size: rem(11);

       @media (--from-small-screen-w) {
            font-size: rem(12);
        }
    }


    // Layout
    &.block {
        display: block; // Fix Safari aligning to the left
        width: 100%;
    }

    &.wide {
        min-width: 33%;
    }

    &.toggle {
       @media (--from-small-screen-w) {
            padding-top: rem(16);
            padding-bottom: rem(16);
        }

        // Types
        &.is-active {
            .not-active-label {
                display: none;
            }

            .toggle-icon {
                transform: rotate(180deg);
            }
        }

        &:not(.is-active) .active-label {
            display: none;
        }

        .not-active-label,
        .active-label {
            padding-right: rem(5);
        }

        .toggle-icon {
            position: relative;
            top: rem(-1);
        }
    }


    // 'icon' button
    svg,
    img {
        margin-right: rem(6);
    }
}

.link {
    color: $color-primary;
    text-decoration: none;
    cursor: pointer;
    font-weight: $weight-light;

    &:hover {
        color: $color-black;
        text-decoration: underline;
    }
}
