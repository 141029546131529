/**
    Tile
*/
.tile {

    // Only displayed on major project pages
    .tile-show--mp {
        display: none;
    }

    // Fix spacing on thumbnail <a> tags
    &-thumbnail-link {
        display: block;
    }

    &-thumbnail {
        width: rem(90);
        height: 100%;
        position: relative;

        @media (--from-small-screen-w) {
            max-height: rem(300);
            width: auto;
            overflow: hidden;
            height: auto;
            display: flex;
        }

        .image-container {
            display: flex;
            width: 100%;
        }

        &:hover {
            @media (--from-large-screen-w) {
                box-shadow: rgb(0 0 0 / 30%) 0 2px 4px 0;
            }
        }

        .hover-area {
            width: 100%;
            transition: box-shadow opacity $base-transition-easing $base-transition-speed;

            //height for different content block image
            .square {

                height: $image-height-square-sm;
                width: $image-height-square-sm;
                position: relative;
                @media (--from-small-screen-w) {
                    height: $image-height-square;
                    width: $image-height-square;
                }
            }

            .rectangle {
                position: relative;
                @media (--from-small-screen-w) {
                    height: $image-height;
                }
            }

            .featured {
                height:  $image-height-square-sm;
                width:  $image-height-square-sm;
                position: relative;
                @media (--from-small-screen-w) {
                    height: $image-height;
                    width:  $image-height;
                }
            }


            @media (--from-small-screen-w) {
                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: block;
                    background-color: rgba(0, 0, 0, 0.5);
                    content: '';
                    opacity: 0;
                    transition: opacity $base-transition-easing $base-transition-speed;
                }

                &:hover {
                    @media (--from-large-screen-w) {

                        &:after,
                        [data-hover-text] {
                            opacity: 1;
                        }

                        [data-hover-text] {
                            top: 50%;
                        }

                        [data-hover-status] {
                            transform: translateY(0);
                        }
                    }
                }

            }

            [data-hover-text] {
                display: none;

                @media (--from-large-screen-w) {
                    display: block;
                }

                position: absolute;
                top: 47%;
                left: 50%;
                z-index: $layer-hover-text;
                text-transform: uppercase;
                padding: rem(4) rem(8);
                color: $color-white;
                font-size: rem(12);
                font-weight: $weight-regular;
                text-align: center;
                white-space: nowrap;

                border: rem(1) solid $color-white;
                border-radius: $box-radius;
                opacity: 0;

                transform: translate(-50%, -50%);

                transition: top $base-transition-easing $base-transition-speed,
                opacity $base-transition-easing $base-transition-speed;
            }

            [data-hover-status] {
                display: none;

                @media (--from-large-screen-w) {
                    display: block;
                }

                text-transform: uppercase;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: $layer-hover-status;
                padding: rem(14) rem(15);
                text-align: right;
                background-color: $color-white;
                transform: translateY(100%);
                transition: transform $base-transition-easing $base-transition-speed;


                svg {
                    vertical-align: middle;
                    margin-right: rem(5);
                }

                p {
                    display: inline-block;
                    margin-bottom: 0;

                    color: $color-black;
                    font-size: rem(12);
                }
            }


        }


        &-icon {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: $layer-position-icon;
            display: block;
            background-color: $color-red;
            width: rem(30);
            height: rem(30);
            background-position: center;
            background-repeat: no-repeat;

            @media (--from-small-screen-w) {
                width: rem(40);
                height: rem(40);
            }

        }

        &-image {
            max-width: 100%;
            object-fit: contain;
            object-position: left top;
            @media (--from-small-screen-w) {
                object-position: left bottom;
            }
            max-height: $image-height;
        }
    }

    &-title {
        margin-bottom: rem(5);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media (--from-small-screen-w) {
            margin-top: rem(10);
            margin-bottom: rem(10);
            -webkit-line-clamp: 3;
        }
    }

    &-initials {
        display: block;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        color: #000;
        font-size: 1.875em;
        font-weight: 700;
        transform: translate(-50%, -50%);
        width: 100%;

        @media (--from-small-screen-w) {
            font-size: 3em;
        }
    }


    &-details {

        margin-left: rem(10);
        display: flex;
        flex-direction: column;
        // margin-top: rem(15);
        @media (--from-small-screen-w) {
            margin-left: 0;
        }
        p {
            margin-top: 0;
            font-size: rem(14);
            &:not(:last-child) {
                margin-bottom: rem(4);
            }
        }

        &-display {

            display: block;
            margin-top: auto;
            svg {
                vertical-align: middle;
                margin-right: rem(5);
            }

            p {
                display: inline-block;
                margin-bottom: 0;

                color: $color-black;
                font-size: rem(12);
            }
            @media (--from-small-screen-w) {
                display: none;
            }
        }
    }

    &-news-date {
        display: none;
        font-weight: $weight-bold;

        @media (--from-small-screen-w) {
            display: block;
        }
    }

    .bg-grey {
        background-color: $color-lighter-grey;
    }
    .bg-red {
        background-color: $color-red;
    }
    .bg-black {
        background-color: $color-black;
    }

    &-hide {
        display: none;
    }

    &-showbtn {
        display: flex;
        justify-content: center;
        margin-top: rem(10);
    }

    &-event-tags {
        @media (--from-small-screen-w) {
            margin-top: rem($vertical-spacing-tiny);
        }
    }

    &-from {
        margin-right: rem(10);
    }

    &-soldout {
        display: flex;

        &-text {
            color: $color-primary;
            font-weight: $weight-medium;
        }
    }

    &-fade {
        opacity: .4;
    }

    &-mobile-fade {
        opacity: .4;
        @media (--from-small-screen-w) {
            opacity: 1;
        }
    }


    &-badge {
        position: absolute;
        top: 0;
        right: 0;
        left: auto;
        padding: 1em;
        font-size: rem(10);
        margin: 0;
        color: $color-white;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 0;
        z-index : $layer-position-icon;
        @media (--from-small-screen-w) {
            padding: 1.3em 1em;
            font-size: rem(16);
        }
    }

    strike {
        text-decoration-color: $color-red;
        text-decoration-thickness: rem(2);
    }

    &-archive-artwork {
        @media (--from-small-screen-w) {
            height: $image-height;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-end;
        }
    }

    &-artist {
        a {
            margin-right: rem(5);
        }
    }

    &-page-type {
        &::first-letter {
            text-transform: uppercase;
        }
    }
}