/*
    Artist List
*/
.artist-list-block {
    .artist-list-section {
        position: relative;
        display: flex;
        min-height: rem(400);

        &.desktop {
            @media screen and (max-width: 720px) {
                display: none;
            }
        }

        &.mobile {
            @media screen and (min-width: 719px) {
                display: none;
            }
        }

        &-bg {
            position: absolute;
            z-index: $layer-artist-bg;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
            max-height: 100%;
            overflow: hidden;

            font-size: rem(100);
            font-weight: 600;
            line-height: 1.2;
            color: $color-light-grey;
            text-align: center;

            @media screen and (max-width: 1120px) {
                font-size: rem(120);
            }

            @media screen and (max-width: 900px) {
                font-size: rem(60);
            }

            @media screen and (max-width: 720px) {
                font-size: rem(100);

                // Child element to restrict text width on mobile
                .bg-content {
                    margin-bottom: 0;
                    width: rem(80);
                }
            }
        }
    }

    .list-group {
        flex-grow: 1;
        flex-basis: 0;

        &-title {
            font-weight: 500;
            margin-bottom: rem(40);
        }

        .artist-list-item {
            display: block;
            margin-bottom: rem(10);

            color: inherit;

            &:hover {
                text-decoration: none;
                color: $color-primary;
            }
        }
    }

    .artist-list-section.mobile {
        flex-direction: column;

        .artist-list-section-titles {
            display: flex;

            & > .list-group-title {
                flex-grow: 1;
                flex-basis: 0;
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;

                &.is-active {
                    color: $color-primary;
                    text-decoration: underline;
                }
            }
        }

        .list-group:not(.is-active) {
            display: none;
        }
    }
}