/*
    Banner
*/
.banner {
    position: relative;

    margin: 0;
    margin-bottom: rem(15px);

    @media (--from-tiny-screen-w) {
        margin-bottom: rem(30px);
    }

    &.inline {
        margin-bottom: 0;
    }

    // Skins
    &.black {
        background-color: $color-black;

        .subheading {
            color: $color-white;
        }
    }

    &.red,
    &.plain {
        // background-color: $color-white; // We don't actually need this, but left in in case page background changes

        .subheading {
            color: $color-black;
        }
    }

    &.plain {
        padding-bottom: rem(15px);

        border-bottom: 1px solid $color-tint-1;

        @media (--from-tiny-screen-w) {
            padding-bottom: rem(30px);
        }
    }


    > img {
        display: block;
        max-width: 100%;
        @media (--from-medium-screen-w) {
            object-fit: cover;

            min-height: rem(240px);
        }
    }

    .caption {
        padding: rem(15px);

        @media (--from-small-screen-w) {
            position: absolute;
            bottom: 0;
            left: 0;

            padding-top: 0;
            padding-right: 0;
            padding-bottom: rem(20px);
            padding-left: rem(30px);
        }

        @media (--from-medium-screen-w) {
            padding-bottom: rem(40px);
        }

        @media print {
            padding-bottom: 0;
            padding-left: 0;
        }

        .heading {
            font-weight: $weight-heavy;
        }
    }

    .heading {
        margin-bottom: rem(8px);

        font-size: rem(26px);

        @media (--from-small-screen-w) {
            font-size: rem(36px);
        }

        @media (--from-medium-screen-w) {
            font-size: rem(62px);
        }
    }

    .subheading {
        margin-bottom: 0;

        font-size: rem(16px);

        @media (--from-small-screen-w) {
            font-size: rem(22px);
        }

        @media (--from-medium-screen-w) {
            font-size: rem(26px);
        }
    }

    .details .field-group {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @media (--from-medium-screen-w) {
            max-width: rem(360);
        }

        @media (--from-normal-screen-w) {
            max-width: rem(450);
        }

        input {
            flex-basis: rem(200);
            flex-grow: 1;
            flex-shrink: 0;
            margin-right: rem(8);
        }

        .button {
            flex-grow: 0;
            flex-shrink: 0;
            margin-left: rem(8);
        }
    }
}
