/*
    What's On Page
*/
.whats-on-search-form {
    margin-bottom: rem(30);

    @media (--from-normal-screen-w) {
        margin-bottom: rem(40);
    }

    .dropdown {
        border-bottom: none;
    }

    .date-range-filter, .reference-filter {
        min-width: rem(200);
    }
}

.whats-on-event {
    .tile-block .tile-block-title {
        display: flex;
        justify-content: space-between;
    }
}