.search-result-grid {
    .filter-groups {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        position: relative;

        @media (--from-medium-screen-w) {
            flex-direction: row;

            & > *:not(:last-child) {
                border-right: rem(1) solid $color-tint-1;
            }
        }
    }

    .tile-block {
        min-height: rem(200);
    }

    .tile-block .tile {
        @media (--from-small-screen-w) {
            flex-basis: calc(100% / 2);
            max-width: calc(100% / 2); // IE flex-wrap
        }

        @media (--from-normal-screen-w) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3); // IE flex-wrap
        }

        @media (--from-large-screen-w) {
            flex-basis: calc(100% / 4);
            max-width: calc(100% / 4); // IE flex-wrap
        }
    }

    .no-results {
        margin-top: rem($vertical-spacing-tiny);
        padding: 0 rem(15);

        @media (--from-small-screen-w) {
            margin-top: rem($vertical-spacing-medium);
            padding: 0 rem(20);
        }
    }

    // Page header has margin, need to set minus margin here as like for like
    .search-bar {
        margin-top: -rem(30);
    }
}