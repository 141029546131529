/*
    Type
*/
html {
    font-family: $font-primary;
    font-size: 16px;
    font-weight: $weight-light;
    line-height: $spacing-secondary;
    text-rendering: geometricPrecision;

    color: $color-dark;

    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    word-break: break-word;
    word-wrap: break-word;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Reset
}

%highlight {
    color: $color-primary;
    font-weight: $weight-regular;
}


// Defaults
%text-title {
    @extend %highlight;

    display: block;

    font-size: rem(16px);
}

%text-bolder {
    font-weight: $weight-medium;
}

%text-bold {
    font-weight: $weight-regular;
}

%text-thin {
    font-weight: $weight-light;
}

%text-light {
    color: $color-light;
}

// Sizes
%text-x-large {
    font-size: rem(20px);
}

%text-large {
    font-size: rem(18px);
}

%text-regular {
    font-size: rem(16px);
}

%text-small {
    font-size: rem(14px);
}

%text-tiny {
    font-size: rem(12px);
}


// Normal text
p {
    margin-top: 0;

    &:not(:last-child) {
        margin-bottom: $padding-height-sm;
    }

    &.small {
        @extend %text-small;
    }

    &.tiny {
        @extend %text-tiny;

        line-height: 1.4;
    }
}

strong {
    font-weight: $weight-medium;
    @extend %text-bolder;
}

address {
    font-style: normal;
}

blockquote {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
}

em {
    font-style: italic;
}

.highlight {
    @extend %highlight;
}

.text-light {
    @extend %text-light;
}

.text-thin {
    @extend %text-thin;
}

.text-small {
    @extend %text-small;
}

hr {
    color: $color-light-grey;

    border: 0;
    border-top: 1px solid;

    &:first-child {
        display: none;
    }
}


// Link
a {
    color: $color-primary;
    text-decoration: none;

    cursor: pointer;

    transition: color $base-transition-speed $base-transition-easing, border $base-transition-speed $base-transition-easing;

    &:hover {
        color: $color-secondary;
        text-decoration: underline;
    }

    &:hover,
    &:active {
        outline: none;
    }

    &.prefixed {
        display: flex;
        align-items: center;

        &:first-child {
            margin-right: 5px;
        }

        svg {
            margin-right: $padding-width-sm;
        }
    }
}



// Transformation
%uppercase {
    text-transform: uppercase;
}

%lowercase {
    text-transform: lowercase;
}

%italic {
    font-style: italic;
}

.uppercase {
    @extend %uppercase;
}

.lowercase {
    @extend %lowercase;
}



// Alignment
.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}
