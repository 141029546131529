/*
    Notification
 */
 .notification {
    position: relative;


    // Types
    &.type-danger {
        background-color: $color-danger;
    }

    &.type-offer {
        background-color: $color-offer;
    }


    // States
    &:not(.is-visible) {
        display: none;
    }

    &.is-uncloseable {
        .notification-content {
            margin-right: 0;
        }
    }


    // Elements
    &-inner {
        position: relative;
        padding-top: rem(15);
        padding-right: rem(36);
        padding-bottom: rem(15);
        padding-left: rem(15);

        color: $color-white;

        @media (--from-small-screen-w) {
            padding-top: rem(20);
            padding-right: rem(46);
            padding-bottom: rem(20);
            padding-left: rem(90);
        }
    }

    &-content {
        margin-right: rem(30);

        @media (--from-small-screen-w) {
            margin: 0 rem(60);
        }
    }

    &-icon {
        display: none;

        @media (--from-small-screen-w) {
            position: absolute;
            top: 50%;
            left: rem(20);

            display: block;
            width: rem(42);
            height: rem(42);

            transform: translateY(-50%);
        }

        @media (--from-medium-screen-w) {
            width: rem(38);
            height: rem(38);
        }

        @media (--from-large-screen-w) {
            width: rem(42);
            height: rem(42);
        }
    }

    &-toggle {
        position: absolute;
        top: rem(15);
        right: rem(15);

        padding: 0;
        margin: 0;

        color: $color-white;

        cursor: pointer;
        background-color: transparent;
        border: none;
        border-radius: 0;

        @media (--from-small-screen-w) {
            top: 50%;
            right: rem(40);

            transform: translateY(-50%);
        }


        // States
        &:hover {
            color: $color-white;
        }


        // Elements
        svg {
            width: rem(20);
            height: rem(20);

            @media (--from-small-screen-w) {
                width: rem(28);
                height: rem(28);
            }

            @media (--from-medium-screen-w) {
                width: rem(24);
                height: rem(24);
            }
        }
    }

    &-title {
        font-weight: $weight-medium;
        text-transform: uppercase;
    }

    p {
        margin-bottom: 0;

        line-height: 1.2;
    }

    a {
        display: inline-block;

        color: $color-white;
        text-decoration: underline;
    }
}
