.sponsor-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (--from-small-screen-w) {
        flex-direction: row;
    }

    // bare sponsor styling (e.g. on event details page)
    .sponsor-tile {
        display: flex;
        flex-basis: 100%;
        flex-direction: row;
        justify-content: flex-end;
        border-bottom: rem(1) solid $color-light-grey;
        padding: rem(15) 0;
        margin: 0;
    
        @media (--from-small-screen-w) {
            flex-basis: 33%;
            flex-direction: column;
            justify-content: flex-end;
            border: none;
            padding-right: rem(14);
            padding-left: rem(14);
            padding-bottom: rem(20);
        }
        @media (--from-normal-screen-w) {
            flex-basis: 25%;
        }

        picture {
            order: 1;
        }
    
        &-image {
            display: block;
            max-width: 100%;
            @media (--from-small-screen-w) {
                width: auto;
            }
        }
    
        &-title {
            order: 2;
            padding-bottom: rem(12);
            margin-bottom: rem(12);
            font-size: rem(14);
            word-break: normal;
            margin-top : rem(10);
            margin-left: rem(12);

            @media (--to-small-screen-w) {
                flex: 1 1 100%;
            }

            @media (--from-small-screen-w) {
                margin-left: 0;
            }
        }
    }

    // default sponsor content block styling
    // also for aex style
    &.default, &.aex {
        .sponsor-tile {
            flex-direction: row-reverse;

            @media (--from-small-screen-w) {
                flex-direction: column;
            }

            picture {
                order: 2;
                max-width: 100%;
            }

            &-image {
                width: rem(120);

                @media (--from-small-screen-w) {
                    width: auto;
                }
            }

            &-title {
                order: 1;
                font-size: rem(16);
                font-weight: 500;
                flex-basis: 0%;
                margin-top: 0;
        
                @media (--from-small-screen-w) {
                    border-bottom: rem(1) solid $color-black;
                }
                > a {
                    color: $color-black;
                }
            }
        }
    }

    // slight difference for aex styling
    &.aex {
        .sponsor-tile {
            justify-content: flex-start;
        }
    }
}
