.article-title-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    // title
    h1 {
        max-width: rem($constrain-width-half);
    }
}
