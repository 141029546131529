// Common defines for content block styles

// common styling for a normal content block section
%content-block-section {
    // margins
    margin-bottom: $padding-height-sm;
    
    @media (--from-tiny-screen-w) {
        margin-bottom: $padding-height-md;
    }
}

// Display 2 columns for text content block
%text-columns {
    p {
        padding-bottom: 1em;
        margin: 0;

        break-inside: avoid;
    }

    @media (--from-medium-screen-w) {
        column-count: 2;
        column-gap: $aex-gutter;
    }
}