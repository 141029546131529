/*
    Navigation
 */
.navigation {
    position: relative;
    padding-top: rem(25);
    padding-left: rem(30);
    padding-bottom: rem(20);
    padding-right: rem(20);
    transition: transform $overlay-transition-open;;

    @media (--from-navigation-breakpoint) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: rem(680);
        order: 2;
        transform: translateY(rem(19));
        padding: 0;
    }

    &.secondary-nav-active {
        width: 200%;
        transform: translateX(-50%);

        .navigation-item {
            .navigation-link {
                width: 50%;
            }

            .sub-navigation {
                position: absolute;
                top: 0;
                left: 50%;
                margin-top: rem(20);
                // don't cover .navigation-bar-inner box shadow
                right: rem(10);
            }
        }
    }

    // Elements
    &-actions {
        display: flex;
        column-gap: rem(4);
        align-items: baseline;

        .icon-button {
            margin-right: rem(5);
        }

        .navigation-toggle {
            margin-right: 0;
            margin-left: rem(-20);
            padding: rem(20);

            &.is-active{
                background-color: $color-primary;
                color: $color-light;
                margin-right: rem(8);
                padding: rem(20) rem(17);
            }
        }

        @media (--from-navigation-breakpoint) {
            display: none;
        }
    }

    &-item {
        display: flex;
        margin-bottom: rem(13);

        @media (--from-navigation-breakpoint) {
            flex-wrap: wrap;
            align-items: center;
            flex-wrap: nowrap;
            margin-bottom: 0;
        }


        // States
        &:hover {
            .navigation-link {
                background-color: $color-primary;

                &.primary,
                .label {
                    color: $color-light;
                }
            }

            .sub-navigation {
                @media (--from-navigation-breakpoint) {
                    display: block;
                    height: auto;
                    overflow: visible;

                    opacity: 1;

                    transform: translate(0, 0);
                    transition:
                        transform 0.2s ease-in-out,
                        opacity 0.2s ease-in-out;
                }
            }
        }

        &.is-active .sub-navigation {
            display: block;
        }

        &.desktop-only {
            display: none;

            @media (--from-navigation-breakpoint) {
                display: flex;
            }
        }
    }

    &-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        height: rem(40);
        line-height: 1;
        letter-spacing: 0.01em; // Increase light on dark
        text-decoration: none;
        transition: background-color 0.2s ease-in-out;
        cursor: pointer;

        @media (--from-navigation-breakpoint) {
            flex-direction: column;
            justify-content: center;
            color: $color-secondary;
            padding-left: rem(16);
            padding-right: rem(16);
        }

        // States
        &.mobile {
            @media (--from-navigation-breakpoint) {
                display: none;
            }
        }

        &.desktop {
            display: none;

            @media (--from-navigation-breakpoint) {
                display: flex;
            }
        }

        .label {
            font-size: rem(24);
            order: 1;
            margin-bottom: 0;
            color: $color-light;

            @media (--from-navigation-breakpoint) {
                font-size: rem(20);
                font-weight: $weight-bold;
                width: 100%;
                order: 2;
                color: $color-secondary;
                text-transform: uppercase;
            }
        }

        &.primary {
            color: $color-primary;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .sub-navigation-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: rem(24);
        height: rem(40);
        padding: 0;

        color: inherit;
        text-align: right;

        background: transparent;
        border: none;

        @media (--to-navigation-breakpoint) {
            flex-grow: 100;
            justify-content: flex-end;
            height: rem(50);
            padding-right: rem(20);
            margin-right: rem(10);
        }


        // Elements
        .icon {
            width: rem(14);
            height: rem(14);

            color: $color-light;

            transition: transform 0.2s ease-in-out;
        }
    }

    // Secondary navigation
    &-secondary {
        display: none;

        @media (--from-navigation-breakpoint) {
            order: 1;
            display: flex;
        }

        & > * {
            display: inline-block;
            padding: 0 rem(5);
            font-size: rem(16);
            line-height: 1em;
            
            &:not(:last-child) {
                border-right: rem(1) solid $color-secondary;
            }
        }
    }
}
