.share-page {
    display: none;
    @media (--from-medium-screen-w) {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-bottom: rem(40);
        margin-top: rem(40);
    }

    &::before {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        height: 1px;
        background-color: #d9d9d9;
        content: '';
    }

    &::after {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        height: 1px;
        background-color: #d9d9d9;
        content: '';
    }
}

.social-sharing {
    display: none;
    @media (--from-medium-screen-w) {
        display: block;
    }
    
    position: relative;

    .share-toggle {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-pack: center;
        justify-content: center;
        width: rem(105);
        padding: rem(6) rem(10);
        background-color: $color-white;
        border: rem(1) solid #d9d9d9;
        color: $color-red;
        cursor: pointer;
    
        &:hover { 
            color: $color-black;
            text-decoration: none;
            border-color: $color-black;
        }
        &::after {
            content: 'Share';
            text-transform: uppercase;
            font-size: rem(12);
        }
        > svg {
            margin-right: rem(14);
        }
    }
    
    .social-links { 
        position: absolute;
        
        display: none;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: rem(12);
        color: $color-white;
        background-color: $color-red;
        
        border-radius: rem(2);
        min-width: rem(190);

        .arrow {
            position: absolute;
            width: rem(20);
            height: rem(20);
            background: linear-gradient(-45deg,#d01533 60%,rgba(208,21,51,0) 60%);
            transform-origin: center;
        }

        // Popup directions
        &.top {
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%,-165%);
            -ms-transform: translate(-50%,-165%);
            transform: translate(-50%,-165%);

            .arrow {
                bottom: 0;
                left: 50%;
                transform: translate(-50%,50%) rotate(45deg);
            }
        }
        
        &.right {
            top: 50%;
            left: 100%;
    
            -webkit-transform: translate(10%, -50%);
            -ms-transform: translate(10%, -50%);
            transform: translate(10%, -50%);

            .arrow {
                bottom: 50%;
                left: 0;
                transform: translate(-50%, 50%) rotate(135deg);
            }
        }

        &.left {
            top: 50%;
            right: 100%;
    
            -webkit-transform: translate(-10%, -50%);
            -ms-transform: translate(-10%, -50%);
            transform: translate(-10%, -50%);

            .arrow {
                bottom: 50%;
                right: 0;
                transform: translate(50%, 50%) rotate(-45deg);
            }
        }

        &.open {
            display: flex;
        }

    }
}

.icon-only {
    display: block;
    color: $color-red;
    @media (--from-navigation-breakpoint) {
        display: none;
    }
    &:hover{
        color: $color-black;
    }
}
