/*
    Form field
 */
 .form-field {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: rem(16);

    // Input container (label + input wrapper)
    .input-container {

    }

    // Input wrapper (input + icon)
    .input-wrapper {
        position: relative;
    
        .icon {
            position: absolute;
            top: 50%;
            right: rem(10);
            width: rem(20);
            height: rem(20);
            transform: translateY(-50%);
        }
    }

    // State
    &.small {
        width: rem(150);
    }

    &.medium {
        width: rem(220);
    }

    &.normal {
        width: rem(300);
    }

    &.large {
        width: rem(420);
    }

    &.is-required .label:after {
        color: $color-error;

        content: '*';
    }

    &.is-invalid {
        color: $color-error;
        margin-bottom: rem(8);

        input,
        .input {
            border: rem(1) solid $color-error;
        }

        .error-message {
            display: block;
        }
    }

    .error-message {
        display: none;
        margin: 0;
        line-height: 1.2;
    }
}
