/*
    Error page
*/
.error-page {
    margin-bottom: rem($vertical-spacing);
    
    // override default banner
    .banner {
        margin-bottom: 0;
    }

    &-description {
        margin-top: rem($vertical-spacing);
    }
}
