/*
    Carousel
*/
.carousel-block {

    // override default section margin
    // TODO: improve all content block margins to use .section
    &.section {
        margin-bottom: rem($vertical-spacing-tiny);

        @media (--from-tiny-screen-w) {
            margin-bottom: rem($vertical-spacing-medium);
        }
    }

    // Elements
    &-inner {
        position: relative;

        width:100%;
    }

    .slider-item {
        display: block;

        &:hover {
            text-decoration: none;
        }
    }

    .image-container {
        height: 20vh;

        @media (--from-tiny-screen-w) {
            height: 36vh;
        }

        @media (--from-medium-screen-w) {
            height: rem(360);
        }

        @media (--from-normal-screen-w) {
            height: rem(400);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content-container {
        padding: rem(20);

        .title {
            margin-bottom: rem(2);
            font-size: rem(16);

            @media (--from-normal-screen-w) {
                font-size: rem(26);
            }
        }

        .subtitle {
            font-size: rem(12);
            line-height: 1.2;
            margin-bottom: 0;

            @media (--from-normal-screen-w) {
                font-size: rem(22);
            }
        }
    }

    // support video in carousel item
    .video-outer {
        display: none;

        @media (--from-medium-screen-w) {
            position: relative;

            display: block;
            width: 100%;
            height: rem(360px);
            overflow: hidden;

            & + .image-container {
                display: none;
            }
        }

        @media (--from-normal-screen-w) {
            height: rem(400px);
        }
    }

    .video {
        position: absolute;
        top: 50%;
        left: 0;

        width: 100%;

        transform: translateY(-50%);

        &::before {
            display: block;
            padding-top: calc(9 / 16 * 100%);

            content: '';
        }
    }

    .video-inner,
    .video-cloak {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }

    // Slider
    .slick-slider {
        position: relative;

        &:hover {
            button.slick-arrow {
                opacity: 1;
                visibility: visible
            }
        }

        button.slick-arrow {
            position: absolute;
            top: 50%;
            z-index: $layer-caption-carousel-slide;
            background-color: $color-light;
            border-radius: 50%;
            width: rem(30);
            height: rem(30);
            transition: all 0.1s;
            cursor: pointer;

            @media (--from-small-screen-w) {
                width: rem(40);
                height: rem(40);
            }

            @media (--from-medium-screen-w) {
                opacity: 0;
                visibility: hidden;
            }

            &.slick-prev {
                left: rem(20);
                transform: rotate(90deg);

                &:hover {
                    color: $color-secondary;
                    transform: translateX(rem(-5)) rotate(90deg);
                }
            }

            &.slick-next {
                right: rem(20);
                transform: rotate(-90deg);

                &:hover {
                    color: $color-secondary;
                    transform: translateX(rem(5)) rotate(-90deg);
                }
            }

            & > svg {
                width: rem(20);
                height: rem(20);
                transform: translateY(rem(3));

                @media (--from-medium-screen-w) {
                    width: rem(25);
                    height: rem(25);
                }
            }
        }

        // pagination dots
        .slick-dots {
            // use absolute positioning on the dots so it doesn't affect slider arrows positioning
            // slider arrows need to be vertically centered on the image
            position: absolute;
            bottom: rem(-45);
            width: 100%;
            
            list-style-type: none;
            margin: 0;
            padding: 0;
            text-align: center;

            li {
                position: relative;
                display: inline-block;
                margin: 0 3px;
                padding: 0;

                button {
                    font-size: 0; // hide the text
                    background-color: #333;
                    opacity: 0.25;
                    width: 16px;
                    height: 6px;
                    cursor: pointer;
                }

                &.slick-active {
                    button {
                        opacity: 1;
                    }
                }
            }
        }
    }

    // style variations for a hero carousel
    &.hero {
        margin-bottom: rem(80);

        .image-container {
            height: rem(300);

            @media (--from-small-screen-w) {
                height: rem(400);
            }

            img {
                object-fit: contain;
            }
        }
    }
}
