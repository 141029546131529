.footer-language-map {
    .desktop {
        display: none;
        @media (--from-normal-screen-w) {
            display: block;
        }

        .localisation-list {
            @extend %unlist;
            @extend %text-small;
    
            > li {
                display: inline-block;
    
                line-height: 1;
    
                &:not(:first-child) {
                    padding-left: rem(5px);
                }
    
                &:not(:last-child) {
                    padding-right: rem(6px);
    
                    border-right: 1px solid $color-black;
                }
            }
        }
    }

    .mobile {
        @media (--from-normal-screen-w) {
            display: none;
        }

        // label is only for accessibility
        label {
            display: none;
        }

        .localisation-select {
            @extend %text-small;
    
            padding-right: rem(12px);
            padding-left: rem(22px);
            margin-bottom: rem(15px);
    
            color: $color-primary;
    
            cursor: pointer;
    
            background-color: transparent;
            background-image: url($folder-icon/globe-icon.svg), url($folder-icon/list-carat-red.svg);
            background-position: left center, right center;
            background-repeat: no-repeat, no-repeat;
            border: 0;
            border-radius: 0;
            outline: none;
    
            appearance: none;
    
            @media (--from-small-screen-w) {
                margin-bottom: 0;
            }
        }
    }
}