// artwork details
%artwork-detail-section {
    margin-top: rem(30);

    @media (--from-small-screen-w) {
        margin-top: rem(40);
    }
}

.artwork-page {
    .btn-request-print {
        float: right;

        @media (--to-small-screen-w) {
            display: none;
        }
    }

    .artwork-details {
        margin-bottom: rem($vertical-spacing);
    }

    .artwork-details-body {
        display: flex;
        flex-wrap: wrap;
        
        .artwork-description {
            @extend %artwork-detail-section;
            width: 100%;
    
            @media (--from-medium-screen-w) {
                width: 50%;
                padding-right: $padding-width;
            }
    
            @media (--from-normal-screen-w) {
                width: 66.67%;
            }
        }
        
        .artwork-tags {
            @extend %artwork-detail-section;
            width: 100%;
            
            @media (--from-medium-screen-w) {
                width: 50%;
            }
    
            @media (--from-normal-screen-w) {
                width: 33.33%;
            }
        }
    }

    .article-header {
        .artist-names {
            color: $color-black;
            
            a {
                color: $color-black;
            }
        }
    }

    .artist-artworks {
        > .heading {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-bottom: 0;

            @media (--from-medium-screen-w) {
                flex-direction: row;
            }

            .btn-view-all {
                margin-top: rem(10);
                margin-right: auto;

                @media (--from-medium-screen-w) {
                    max-width: rem(200px);
                    margin-top: 0;
                    margin-right: 0;
                    margin-left: auto;
                }
            }
        }

        .artist-link {
            font-weight: 400;
        }
    }

    // Request a print and reproduction enquiry forms
    .artwork-request-modal {
        .no-margin {
            margin: 0;
        }
        
        .title {    
            .artist {
                margin-right: $padding-width-sm;
            }
    
            .artwork {
                @extend %italic;
            }
        }
    }
}
