/*
    Swatch
*/
$--grid-spacing: 20;

.swatch {

    // Types
    &.outline .swatch-inner {
        color: $color-dark;

        border: rem(1) solid $color-dark;


        // Elements
        .swatch-title::after {
            color: $color-dark;
        }
    }


    // Colours
    &.colour-primary .swatch-inner {
        background-color: $color-primary;


        // Elements
        .swatch-title::after {
            content: '$color-primary';
        }
    }

    &.colour-secondary .swatch-inner {
        background-color: $color-secondary;


        // Elements
        .swatch-title::after {
            content: '$color-secondary';
        }
    }


    // Elements
    &-inner {
        position: relative;

        padding-bottom: rem(40);
        overflow: hidden;

        color: $color-light;

        border-radius: rem(3);

        @media (--from-small-screen-w) {
            padding-bottom: rem(60);
        }
    }

    &-child,
    &-title {
        margin-bottom: 0;
    }

    &-child,
    &-title::after {
        font-size: rem(12);
    }

    &-child {
        padding: rem(10) rem(20);
    }

    &-title {
        padding: rem(20);
    }

    &-title,
    &-variation {

        &::after {
            display: block;

            text-transform: uppercase;

            opacity: 0.8;
        }
    }

    &-variations {
        position: absolute;
        bottom: 0;
        left: 0;

        display: flex;
        width: 100%;
        padding: 0;
        margin: 0;

        list-style: none;
    }

    &-variation {
        flex-basis: 100%;
        height: rem(20);
    }
}
