.spec-details {
    max-width: $width-spec-details;
    margin-top: rem(30);

    &:first-child {
        margin-top: 0;
    }

    dl {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 0;
        margin-bottom: $padding-height;

        @media print {
            page-break-before: always;
        }
    }

    dt {
        @extend %text-bold;

        width: $specs-def-title-width;
        padding-right: rem(20px);
        margin-bottom: rem(10px);

        &:first-letter {
            text-transform: uppercase;
        }
    }

    dd {
        width: calc(100% - $specs-def-title-width);
        margin-left: 0;

        &:not(:last-child) {
            margin-bottom: $padding-height-xs;
        }

        // sub lists
        dt {
            flex-basis: 40%;
        }

        dd {
            flex-basis: 60%;
        }
    }

    .button {
        @media (--from-small-screen-w) {
            margin-left: $specs-def-title-width;
        }
    }
}
