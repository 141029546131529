.artist-page {
    .btn-view-all {
        float: right;
    }
    
    // overrides
    .spec-details {
        margin-top: 0;
        margin-bottom: rem(50);
    }
}
