.multiple-select {
    margin-bottom: rem(20);

    .label {
        display: block;
        margin-bottom: rem(10);
    }

    .input-container {
        position: relative;
    }

    .dropdown {
        display: flex;
        justify-content: space-between;
        border: rem(1) solid $color-tint-1;
        height: rem(40);

        .label {
            margin: auto;
            margin-left: rem(10);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .arrow {
            width: rem(40);
            height: rem(40);
            background-color: $color-primary;

            svg {
                margin: rem(12);
            }
        }
    }

    .options {
        // ensures the multi select dropdown doesn't extend beyond the bottom of the page contents
        max-height: rem(280);
        
        @media (--from-medium-screen-w) {
            max-height: rem(350);
        }

        overflow-y: auto;
        flex-direction: column;
        flex-wrap: wrap;

        span {
            color: $color-secondary;
        }

        &.is_hidden {
            visibility: hidden;
        }

        .field-group.checkbox {
            margin-right: rem(10);
        }
    }
}