/*
    Minimalistic video block
*/
.minimalistic-video-block {

    .block-content {
        display: flex;
        flex-direction: column;

        @media (--from-normal-screen-w) {
            flex-direction: row;
            justify-content: flex-start;
        }
    }

    .text-section {
        flex-basis: auto;
        margin-bottom: rem(20);

        @media (--from-normal-screen-w) {
            flex-basis: rem(300);
            margin-right: rem(50);
        }

        .title {
            font-weight: $weight-medium;
            line-height: 1.2em;
            margin-bottom: rem(30);
        }
    }

    .video-section {
        flex: 1;
        max-width: rem(800);
    }

    .video-container {
        width: 100%;
        position: relative;
        padding-top: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
        background-color: $color-black;

        .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }   
    }
}