// artwork details
%archive-detail-section {
    margin-top: rem(30);

    @media (--from-small-screen-w) {
        margin-top: rem(40);
    }
}

.archive-page {
    .archive-details {
        margin-bottom: rem($vertical-spacing);
    }

    .archive-details-body {
        display: flex;
        flex-wrap: wrap;
        
        .archive-description {
            @extend %artwork-detail-section;
            width: 100%;
    
            @media (--from-medium-screen-w) {
                width: 50%;
                padding-right: $padding-width;
            }
    
            @media (--from-normal-screen-w) {
                width: 66.67%;
            }
        }
        
        .archive-tags {
            @extend %artwork-detail-section;
            width: 100%;
            
            @media (--from-medium-screen-w) {
                width: 50%;
            }
    
            @media (--from-normal-screen-w) {
                width: 33.33%;
            }
        }
    }
}
