// provide seamless fixed header which scrolls down with the top headroom
%headroom {
    .headroom {
        transition: transform $headroom-transition;
    }
    // Toggling of the fixed class is handled by react component
    &.fixed .headroom {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: $layer-header;
    }
}

// Specific styles for advanced exhibition
.aex-page {
    // Pad bottom between here and share section
    @media (--from-small-screen-w) {
        margin-bottom: rem(40);
    }

    .hero-banner {
        position: relative;
        z-index: $layer-banner; // To go over new white background on travelling sidebar
    
        [data-hero-image='mobile'] {
            display: none;
            width: 100%;
        }
    
        [data-hero-image='desktop'] {
            position: absolute;
            top: 0;
            left: 0;
    
            display: none;
            width: 100%;
            height: 100%;
    
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        // Display desktop image if viewport width is longer than height
        @media only screen and (orientation: landscape) {
            // initial height
            height: calc(95vh);
    
            [data-hero-image='desktop'] {
                display: block;
            }
        }
    
        // Display mobile image if viewport height is longer than width
        @media only screen and (orientation: portrait) {
            .btn-proceed {
                display: none;
            }
    
            [data-hero-image='mobile'] {
                display: block;
            }
        }
    }

    // Multiple elements are rendered for desktop and mobile and display toggled via media queries
    .aex-header {
        width: 100%;
        overflow: hidden;
        display: none;

        &.aex-header-desktop {
            @media (--from-medium-screen-w) {
                display: block;
            }

            position: absolute;
            bottom: 0;
            left: 0;

            .aex-headroom {
                @extend %headroom;
            }

            .aex-header-contents {
                width: 100%;
                padding: rem(25) 0;
    
                transform: translateY(150%);
                
                @media (--from-normal-screen-w) {
                    display: flex;
                    justify-content: space-between;
    
                    .aex-header-title,
                    .aex-header-dates {
                        max-width: 55%;
                    }
    
                    .aex-header-dates {
                        padding-top: 0.3em;
                    }
                }
            }
        }

        &.aex-header-mobile {
            @media (--to-medium-screen-w) {
                display: block;
            }

            padding: rem(12) rem(15);

            .aex-header-title,
            .aex-header-dates {
                letter-spacing: 0.05em;
            }

            .aex-header-title {
                font-weight: 400;
                line-height: 1.3em;
                text-transform: none;
            }

            .aex-header-dates {
                padding-top: 10px;
            }
        }

        .aex-header-title {
            margin: 0;
    
            font-size: 18px;
    
            @media (--from-small-screen-w)  {
                font-size: 22px;
            }
    
            @media (--from-medium-screen-w) {
                font-size: 27px;
            }
    
            @media (--from-normal-screen-w) {
                font-size: 29px;
            }
        }
    
        .aex-header-dates {
            margin: 0;
    
            font-size: 16px;
            font-weight: $weight-regular;
            white-space: nowrap;
    
            @media (--from-medium-screen-w) {
                font-size: 20px;
            }
    
            time {
                white-space: nowrap;
            }
        }
    }

    // Collapsible nav fixed to the top on scroll (mobile only)
    .aex-mobile-nav {
        display: none;
        @media (--to-medium-screen-w) {
            display: block;
        }

        .aex-headroom {
            @extend %headroom;

            position: relative;

            .headroom {
                background-color: $color-white;
            }

            .dropdown-arrow {
                z-index: $layer-header-close-button;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: transform $headroom-transition;
                right: rem(10);

                &.open {
                    transform: rotate(180deg);
                    color: $color-white;
                }
            }
    
            .aex-mobile-navbar {
                display: flex;
                padding: rem(10) rem(15);
                cursor: pointer;
                border-bottom: 1px solid $aex-col-border;
    
                .aex-mobile-section-label {
                    flex-grow: 1;
        
                    color: $aex-col-text-action;
                    font-size: 24px;
                    font-weight: 500;
                }
    
                .btn-booking {
                    margin-right: rem(25);
                }
            }

            // Navbar dropdown
            .aex-mobile-navbar-dropdown {
                position: absolute;
                width: 100%;
                top: 0;
                z-index: $layer-header;
                background-color: $color-white;
                transition: transform $headroom-transition;
                transform-origin: top;
                transform: scaleY(0);

                &.open {
                    transform: scaleY(1);
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                }

                .aex-header-mobile {
                    cursor: pointer;
                }
    
                // Navbar dropdown contents
                .aex-navbar {
                    display: flex;
                    padding: rem(20) rem(15);
    
                    .aex-navbar-links {
                        flex-basis: 50%;
                    }
    
                    .aex-navbar-actions {
                        flex-basis: 50%;
                    }
                }
            }

            // Overlay for open dropdown
            .aex-dropdown-mask.open {
                position: fixed;
                top: 0;
                left: 0;

                width: 100%;
                height: 100vh;

                background: rgba(0, 0, 0, 0.7);
            }
        }
    }

    // common navbar contents
    .aex-navbar {
        width: 100%;

        .aex-navbar-links {
            a {
                display: block;
                width: auto;
                margin-bottom: 15px;
        
                color: $aex-col-text-main;
                font-size: 20px;
                font-weight: 500;
        
                &.is-active,
                &:hover {
                    color: $aex-col-text-action;
                    text-decoration: none;
                }
            }
        }
        
        .aex-navbar-actions {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .social-sharing {
                display: block;
                padding-bottom: 1px; // Seem necessary to put popup in correct spot
                margin-top: rem(20);
            }

            .share-toggle, .button {
                width: 130px;
                height: 40px;
            }
        }
    }

    // A relatively positioned wrapper for the travelling sidebar to anchor itself to
    .aex-page-contents {
        position: relative;

        // sidebar for desktop only
        .aex-desktop-sidebar {
            position: absolute;
            left: $aex-gutter;
            z-index: $layer-sidebar;
        
            display: none;
            width: $aex-travelling-sidebar-width;
            // use padding instead of margin so that js can easily calculate the sidebar height for scroll handling purposes
            padding-top: calc($aex-inter-section-margin/2);
            transition: top $headroom-transition;
        
            @media (--from-medium-screen-w) {
                display: block;
            }

            // We need this hacky positioning to follow the constrain width padding when the travelling sidebar becomes fixed
            @media (--from-large-screen-w) {
                left: 50%;
        
                margin-left: calc($aex-max-content-width / -2);
            }

            .aex-navbar-actions {
                margin-top: rem(30);
            }
        }
        
        .aex-sections {
            position: relative;
            
            @media (--from-medium-screen-w) {
                padding-left: calc($aex-travelling-sidebar-width + ($aex-gutter * 2));
                padding-right: $aex-gutter;
    
            }
        }    
    }

    // Sections layout
    .aex-sections {
        @media (--to-medium-screen-w) {
            border-bottom: 1px solid $aex-col-border;
        }

        > section {
            // Padding calculations
            $sm-min:    calc($aex-inter-section-margin/4);
            $sm-small:  rem(20);
            $sm-medium: calc($aex-inter-section-margin/2);
            $sm-xlarge: calc($aex-inter-section-margin);

            padding-top: $sm-min;
            padding-bottom: $sm-min;

            @media (--from-small-screen-w)  {
                padding-top: $sm-small;
                padding-bottom: $sm-small;
            }
            
            @media (--from-medium-screen-w) {
                padding-top: $sm-medium;
                padding-bottom: $sm-medium;
            }

            // Content block margins
            .aex-content-block {
                // TODO: can we get rid of these stuffs
                .sponsor {
                    justify-content: flex-start;
                }

                & + .aex-content-block {
                    margin-top: 20px;
                    @media (--from-normal-screen-w) { margin-top: 40px; }
                }

                &.aex-break:last-child {
                    margin-bottom: -$sm-min;
                    @media (--from-small-screen-w)  { margin-bottom: -$sm-small;  }
                    @media (--from-medium-screen-w) { margin-bottom: -$sm-medium; }
                    @media (--from-x-large-screen-w) { margin-bottom: -$sm-medium; }
                }
            }

            .aex-section-title + *,
            .aex-section-subtitle + * {
                margin-top: 0 !important;
            }
        }
    }

    .aex-section-title {
        padding-bottom: 0.9rem;
        margin-bottom: rem(20);

        font-size: rem(20);
        font-weight: $weight-medium;
        text-transform: none;

        border-bottom: 1px solid $aex-col-border;

        @media (--from-medium-screen-w) {
            margin-bottom: rem(30);

            font-size: 26px;
        }
    }
}