/*
    Sub Navigation
 */
$--dropdown-width: 280;

.sub-navigation {
    display: none;

    @media (--to-navigation-breakpoint) {
        flex-basis: 100%;
        padding-bottom: rem(20);
        padding-left: rem(20);
        overflow-y: hidden;

        color: $color-light;
        background-color: $color-primary;
    }

    @media (--from-navigation-breakpoint) {
        position: absolute;
        top: 100%;
        left: 0;

        width: 100%;
        height: 0;
        overflow: hidden;

        color: $color-light;
        border-top: rem(5) solid $color-primary;
        background-color: $color-light;
        box-shadow: rgb(0 0 0 / 30%) 0 2px 4px 0;
        padding: rem(30) rem(20);

        opacity: 0;

        transition:
            height 0 ease-in-out 0.15s,
            overflow 0 ease-in-out 0.15s,
            opacity 0.15s ease-in-out;
    }

    // States
    &.is-active {
        color: $color-light;
    }


    // Elements
    &-controls {
        display: flex;
        align-items: center;   
        margin-bottom: rem(20);
        
        @media (--from-navigation-breakpoint) {
            display: none;
        }

        & > *:not(:last-child) {
            margin-right: rem(16);
        }

        .current-item {
            font-size: rem(24);
            color: $color-light;
            margin-bottom: 0;
        }
    }

    &-title {
        display: none;
        color: $color-secondary;
        margin-bottom: rem(12);

        @media (--from-navigation-breakpoint) {
            display: block;
        }
    }

    &-inner {
        @media (--to-navigation-breakpoint) {
            // take up approximately full viewport (excluding .sub-navigation-controls and padding), so that excess content can  be scrolled
            height: 90vh;
            overflow-y: scroll;
            // hotfix: add enough vertical padding so that mobile browser bars won't hide the menu items
            // on chrome mobile the address and bottom tabs bars will pop up but not change the viewport height, so it will cover some of this element
            // this will add extra padding at the bottom of the subnavigation, but better than cutting off menu items
            // see https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
            padding-bottom: rem(100);
        }

        @media (--from-navigation-breakpoint) {
            columns: 3;
        }
    }

    &-section {
        margin-bottom: rem(14);
        break-inside: avoid;
        word-wrap: break-word;
        white-space: normal;
    }

    &-item {
        display: block;
        position: relative;
        padding-left: rem(25);
        margin-bottom: rem(10);

        transition:
            color ease-in-out 0.15s,
            background-color ease-in-out 0.15s;

        @media (--from-navigation-breakpoint) {
            margin-bottom: 0;
        }

        &:hover {
            text-decoration: none;

            @media (--from-navigation-breakpoint) {
                text-decoration: underline;
            }
        }

        &.primary {
            font-size: rem(18);
            font-weight: $weight-regular;
            color: $color-light;

            &:hover {
                @media (--from-navigation-breakpoint) {
                    color: $color-dark;
                }
            }

            @media (--from-navigation-breakpoint) {
                color: $color-primary;
            }

            .icon {
                display: none;

                @media (--from-navigation-breakpoint) {
                    display: inline;
                }
            }
        }

        &.secondary {
            font-size: rem(16);
            color: $color-light;

            &:hover {
                @media (--from-navigation-breakpoint) {
                    color: $color-primary;
                }
            }

            @media (--from-navigation-breakpoint) {
                color: $color-dark;
            }
        }

        .icon {
            position: absolute;
            left: 0;
            top: 0;
            color: $color-light;
            transform: rotate(-90deg);
            width: rem(25);
            height: rem(25);

            @media (--from-navigation-breakpoint) {
                color: $color-primary;
            }
        }
    }
}
