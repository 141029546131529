/*
    Tile Blocks
 */
.tile-block {
    padding-bottom: rem($vertical-spacing-tiny);

    overflow-x: hidden; // Reduces overflow on body when using negative margin

    @media (--from-tiny-screen-w) {
        padding-bottom: rem($vertical-spacing-medium);
    }

    @media (--from-medium-screen-w) {
        // hack - to find a better solution
        margin-right: -rem(14);
        margin-left: -rem(14);
    }

    // Elements
    &-inner {
        @media (--from-small-screen-w) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-title {
        margin-bottom: rem($vertical-spacing-medium);

        @media (--from-medium-screen-w) {
                    // hack - to find a better solution
            margin-left: rem(14);
            margin-right: rem(14);
            margin-bottom: rem($vertical-spacing);
        }
    }

    .tile {

        display: flex;
        flex-direction: row;
        padding-top: rem($vertical-spacing-tiny);
        padding-bottom: rem($vertical-spacing-tiny);
        border-bottom: 2px solid $color-light-grey;
    
        @media (--from-small-screen-w) {
            flex-direction: column;
            padding-top: rem($vertical-spacing-small);
            padding-bottom: rem($vertical-spacing-small);
            border-bottom: 0;
            padding: 0;
        }

        @media (--from-small-screen-w) {
            padding-right: $padding-height-sm;
            padding-left: $padding-height-sm;
            padding-top: $padding-width-sm;
            margin-bottom: rem($vertical-spacing-medium);
        }
            

        @media (--from-small-screen-w) {
            flex-basis: calc(100% / 2);
            max-width: calc(100% / 2); // IE flex-wrap
        }

        @media (--from-normal-screen-w) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3); // IE flex-wrap
        }

        @media (--from-large-screen-w) {
            flex-basis: calc(100% / 4);
            max-width: calc(100% / 4); // IE flex-wrap
        }

        &-inner {
            @media (--to-small-screen-w) {
                border-bottom: 2px solid $color-light-grey;
            }

            @media (--from-small-screen-w) {
                padding-right: rem(20);
                padding-left: rem(20);
            }
        }
    }

    .tile-featured {


        display: flex;
        flex-direction: row;
        padding-top: rem($vertical-spacing-tiny);
        padding-bottom: rem($vertical-spacing-tiny);
        border-bottom: 2px solid $color-light-grey;

        @media (--from-small-screen-w) {
            flex-direction: column;
            padding-top: rem($vertical-spacing-small);
            padding-bottom: rem($vertical-spacing-small);
            border-bottom: 0;
            padding: 0;
        }

        @media (--from-small-screen-w) {
            padding-right: $padding-height-sm;
            padding-left: $padding-height-sm;
            padding-top: $padding-width-sm;
            margin-bottom: rem($vertical-spacing-medium);
        }
            

        @media (--from-normal-screen-w) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3); // IE flex-wrap
        }


        &-inner {
            @media (--to-small-screen-w) {
                border-bottom: 2px solid $color-light-grey;
            }

            @media (--from-small-screen-w) {
                padding-right: rem(20);
                padding-left: rem(20);
            }
        }

        &.tile-display-2 {
            @media (--from-normal-screen-w) {
                flex-basis: calc(100% / 2);
                max-width: calc(100% / 2); // IE flex-wrap
            }
        }
    }

    .featured-row { 
        display: flex;
        align-content: flex-end;
        justify-content: space-between;
    }
}
