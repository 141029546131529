.search-dialog .header {
    border: none;
}
.search-dialog .header-inner {
    justify-content: space-between;
    .button {
        order: 1;
    }
}
.back-to-top {
    position: fixed;
    right: 5vw;
    bottom: rem(-80);
    padding-left: rem(10);
    padding-right: rem(5);
    z-index: $layer-back-to-top;
    width: rem(40);
    height: rem(40);
    background: $color-white;
    -webkit-transition: bottom ease-in-out .2s;
    transition: bottom ease-in-out .2s;
}
.show-button {
    bottom: rem(100);
}
.global-search-result-page {
    .global-search-inner {
        padding: rem(70) rem(15);

        @media (--from-normal-screen-w) {
            padding: rem(244) rem(20);
        }
    }

    .field-group.shrink {
        border-left: none;
    }

    .search-modal-note {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: rem(690);
        margin: auto;
        padding-top: rem(45);
        margin-bottom: rem(20);

        @media (--from-normal-screen-w) {
            padding-top: rem(95);
        }

        p {
            text-align: center;
        }
    }
}

.search-categories-wrapper {
    border-top: 0.0625rem solid rgb(217, 217, 217);

    @media (--from-normal-screen-w) {
        border: none;
    }
    
    .search-tags {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: rem(1) solid $color-tint-1;
        padding: rem(7) 0;

        @media (--from-normal-screen-w) {
            padding: 0;
        }

        .search-categories-dropdown {
            display: inline-block;
            max-width: 100%;
    
            @media (--from-normal-screen-w) {
                display: none;
            }
        }
    
        .search-categories-row {
            display: none;
    
            @media (--from-normal-screen-w) {
                display: flex;
            }
    
            .search-modal-category {
                margin-right: rem(20);
                margin-bottom: rem(-1);
                font-weight: $weight-light;
                cursor: pointer;
                padding: rem(20) rem(2);
        
                @media (--from-normal-screen-w) {
                    margin-right: rem(28);
                }
        
                &.is-active {
                    color: $color-primary;
                    border-bottom: rem(1) solid $color-primary;
                }
            }
        }
    }

    //filter groups
    .filter-groups {
        position:relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: rem(12) 0;

        .filter-group-label {
            margin-right: rem(10);
            flex-shrink: 0;
        }

        .search-categories-dropdown, .filter-dropdown {
            flex-grow: 1;
            flex-basis: 35%;
        }

        @media (--from-small-screen-w) {
            // allow first dropdown to grow
            .search-categories-dropdown {
                max-width: 100%;
            }
        }

        @media (--from-normal-screen-w) {
            .search-categories-dropdown, .filter-dropdown {
                flex-grow: 0;
                flex-basis: auto;
            }
        }
    }

    .filter-dropdown {
        width: auto;
    }

    .filter-dropdown .dropdown {
        justify-content: flex-end;
        border: none;

        .button{
            width: auto;
        }
        .button svg {
            margin-left: rem(8);
            margin-right: 0;
        }
    }

    .event-options, .exhibition-options, .archive-options {
        flex-direction: column;

        // Preset options for date range
        .quick-select {
            margin-bottom: rem($vertical-spacing-small);
        }

        .quick-select button, .event-type button {
            margin-right: rem(5);
            margin-bottom: rem(10);

            &.selected {
                color: $color-black;
            }
        }

        .date-options {
            margin-bottom: rem($vertical-spacing-small);
            max-width: 100%;
        }
        
        .date-range {
            display: flex;
            align-items: center;
            margin-bottom: rem(20);
        }
        .date-picker {
            width: auto;
            margin: 0;
        }
        .highlight {
            padding: 0 rem(5);
            color: $color-primary;
        }
    }

    .search-categories-dropdown {
        display: inline-block;
        position: relative;
        margin-right: rem(16);
        max-width: 33%;

        .system-select {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        .button {
            width: 100%;
            text-transform: capitalize;
        }
        .button span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .button svg {
            margin-left: rem(8);
            margin-right: 0;
            flex-shrink: 0;
        }
    }

    .filter-dropdown .button {
        text-transform: none;
        width: 100%;
        justify-content: space-between;

        svg {
            margin-left: rem(8);
            margin-right: 0;
        }
    }

    .search-categories-dropdown .button {
        justify-content: space-between;
    }
}