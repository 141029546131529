/*
    Icon Button
 */
.icon-button {
    padding: 0;
    margin: 0;

    color: inherit;
    font-family: inherit;

    background-color: transparent;
    border: none;


    // Types
    &.small {
        .icon {
            width: rem(20);
            height: rem(20);
        }
    }


    // Elements
    &-inner {
        // Safari doesn't support align-items center on <button> elements
        display: flex;
        align-items: center;
    }

    &-icon {
        flex-grow: 0;
    }
}
