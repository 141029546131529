/*
    Rolling text Block
*/
.rolling-text-block {
    display: flex;
    flex-direction: column;
    margin-top: rem(20);
    margin-bottom: rem(100);

    .heading, .rolling-text-wrapper {
        padding: rem(20) 0;
    }

    .heading h3 {
        font-weight: $weight-medium;
        line-height: rem(30);
    }

    .slick-track{
        display: flex;
        flex-direction: row;
    }

    .slick-slide {
        width: 100%;
        max-width: calc(100vw - rem(40));
        opacity: 0.2;

        @media (--from-normal-screen-w) {
            width: calc(50vw - rem(40));
            max-width: rem(700);
        }
    }

    .slick-active {
        opacity: 1;
    }
    
    // Slider
    .slick-slider {

        @media (--from-medium-screen-w) {
            position: relative;
        }

        button.slick-arrow {
            position: absolute;
            top: 30%;
            z-index: $layer-strip;
            background-color: $color-light;
            border-radius: 50%;
            transition: all 0.1s;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;

            @media (--from-normal-screen-w) {
                width: rem(40);
                height: rem(40);
                opacity: 1;
                visibility: visible;
            }

            &.slick-prev {
                left: rem(-50);
                transform: rotate(90deg);

                &:hover {
                    color: $color-secondary;
                    transform: translateX(rem(-5)) rotate(90deg);
                }
            }

            &.slick-next {
                right: 0;
                transform: rotate(-90deg);

                &:hover {
                    color: $color-secondary;
                    transform: translateX(rem(5)) rotate(-90deg);
                }
            }

            & > svg {
                width: rem(20);
                height: rem(20);
                transform: translateY(rem(3));

                @media (--from-medium-screen-w) {
                    width: rem(25);
                    height: rem(25);
                }
            }
        }
    }

    .slick-dots {
        ul {
            display: flex;
            justify-content: center;
            list-style: none;
            padding: 0;
        }

        li {
            width: rem(40);
            height: rem(3);
            margin: 0 rem(8);
            background-color: black;
            opacity: 0.25;

            &.slick-active {
                opacity: 1;
            }
        }

        button {
            color: transparent;
            width: 100%;
            cursor: pointer;
        }
    }

    // Add padding for each slide
    .slick-slide {
        
        &:not(:last-child) {
            margin-right: rem(30);
        }

        @media (--from-normal-screen-w) {
            padding-left: rem(20);
        }
    }

    @media (--from-normal-screen-w) {
        flex-direction: row;
        .heading {
            width: 30%;
        }
    
        .rolling-text-wrapper {
            width: 70%;
        }
    }
}
