/*
    Pagebreak-algorithm
*/
.pagebreak-algorithm {
    &.default {
        @media (--from-normal-screen-w) {
            width: 75%;
        }
    }

    &.aex {
        width: 100%;

        .algorithm {
            overflow: hidden;
            
            img {
                max-height: $aex-break-max-height;
                @media (--from-small-screen-w) {
                    opacity: 0.5;
                }
            }

            .blocks {
                // for aex always keep the blocks centered
                justify-content: center;
                padding: 0;
                
                @media (--to-small-screen-w) {
                    padding: rem(30);
                }
        
                @media (--from-small-screen-w) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
    
                @media (--from-large-screen-w) {
                    left: 10%;
                    transform: translate(0, -50%);
                }
            }
        }
    }
}

.algorithm {
    position: relative;

    background-color: $color-black;

    // algorithm on the home page
    &-home {
        display: none;
        @media (--from-medium-screen-w) {
            display: block;
            background-image: url($folder-image/promo/info.jpg);
            background-position: right center;
            background-size: cover;
            color: $color-light;
            height: rem(240);
        }
    
        &-left {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: rem(40);
            padding-right: rem(40);
            padding-bottom: rem(25);
            
            > h2 {
                margin-bottom: 0;
                font-size: rem(32);
                text-transform: none;
            }
            
            > h3 {
                margin-bottom: rem(10);
                font-size: rem(18);
                font-weight: 300;
            }

            > p {
                margin-top: rem(15);
                margin-bottom: rem(10);
                font-size: rem(22);
            }

            > button {
                margin-top: rem(45);
            }
        }

        &-right {
            position: absolute;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: flex-end;
            justify-content: right;
            padding-left: rem(40);
            padding-right: rem(40);
            padding-bottom: rem(25);

            > .text-block {
                font-size: rem(50);
                @media (--from-normal-screen-w) {
                    font-size: rem(64);
                }
                text-transform: uppercase;
                font-kerning : normal;
                font-weight : 700;
                line-height: 0.9;
                letter-spacing: rem(1.5);
                white-space: pre;
                margin-bottom: 0;
                text-align: start;
            }
    
            > .white {
                color: $color-white;
            }
    
            > .red { 
                color: $color-red;
            }


        }
    }
    // Skins
    &.red {
        background-color: $color-primary;

        .block:nth-child(1),
        .block:nth-child(3) {
            color: $color-black;
        }

        .block:nth-child(2) {
            color: $color-white;
        }
    }

    // Content
    .details,
    .blocks {
        padding-right: rem(15px);
        padding-bottom: rem(10px);
        padding-left: rem(15px);

        @media (--from-small-screen-w) {
            position: absolute;
            bottom: 0;
        }

        @media (--from-medium-screen-w) {
            padding-right: rem(30px);
            padding-bottom: rem(15px);
            padding-left: rem(30px);
        }

        @media (--from-normal-screen-w) {
            padding-right: rem(40px);
            padding-bottom: rem(25px);
            padding-left: rem(40px);
        }

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

        &.top {
            top: 0;

            padding-top: rem(25px);

            @media (--from-medium-screen-w) {
                padding-top: rem(60px);
            }
        }
    }

    .details {
        display: none; // Not built for small screens
        align-items: flex-start;
        flex-direction: column;

        color: $color-white;

        @media (--from-medium-screen-w) {
            display: flex;
        }

        > h2,
        .opening-message > h2 {
            margin-bottom: rem(8px);

            font-size: rem(32px);
            text-transform: none;
        }

        > h3,
        .opening-message > h3 {
            margin-bottom: rem(20px);

            font-size: rem(18px);
        }

        > p {
            margin-bottom: rem(10px);

            font-size: rem(22px);
        }

        > .field-group {
            width: 100%;
            margin-top: rem(10px);
        }

        input {
            color: $color-black;
        }

        a {
            color: $color-white;
        }
    }

    .blocks {
        display: flex;
        align-items: flex-end;
        padding-top: rem(25px);

        @media (--from-small-screen-w) {
            padding-top: 0;

            background-color: transparent;
        }
    }

    .block {
        @extend .uppercase;

        margin-bottom: 0;

        font-kerning: normal;
        font-size: rem(26px);
        font-weight: $weight-heavy;
        line-height: 0.9;
        letter-spacing: 0.09375em; // 6 / 64 px
        white-space: pre;

        @media (--from-tiny-screen-w) {
            font-size: rem(32px);
        }

        @media (--from-small-screen-w) {
            font-size: rem(40px);
        }

        @media (--from-medium-screen-w) {
            font-size: rem(50px);
        }

        @media (--from-normal-screen-w) {
            font-size: rem(64px);
        }

        &:nth-child(1),
        &:nth-child(3) {
            color: $color-white;
        }

        &:nth-child(1) {
            text-align: right;
        }

        &:nth-child(2) {
            color: $color-primary;
            text-align: center;
        }

        &:nth-child(3) {
            text-align: left;
        }
    }

    .text-hidden {
        display: none;
    }

    img {
        display: block;
        max-width: 100%;
        width: 100%;
    }
}
