/*
    Single image
*/
.image-block {

    &.section {
        margin-bottom: rem(15);

        @media (--from-tiny-screen-w) {
            margin-bottom: rem(30);
        }
    }

    figure {
        margin: 0;
        display: block;

        @media (--from-normal-screen-w) {
            display: flex;
        }

        &.hero {
            .image-container {
                flex-basis: 100%;

                img {
                    max-height: rem(450);
                    margin: 0 auto;
                }
            }
        }

        .image-container {
            @media (--from-normal-screen-w) {
                flex-basis: 75%;
            }
            img {
                display: block;
                max-width: 100%;
            }
        }

        .sidecaption {
            padding-top: rem(25);
            padding-bottom: rem(8);
            border-bottom: 1px solid $color-tint-1;
            @media (--from-medium-screen-w) {
                width: 50%;
            }
            @media (--from-normal-screen-w) {
                flex-basis: 25%;
                border-top: 1px solid $color-tint-1;
                border-bottom: none;
                margin-right: rem(10);
                margin-left: rem(20);
            }
        }
    }
}
