/*
    Navigation tabs
    e.g. for school booking pages
*/
.tabs {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(30px);

    @media (--from-medium-screen-w) {
        flex-direction: row;
    
        border-bottom: 5px solid $color-primary;
    }

    .tabs-intro {
        @media (--from-medium-screen-w) {
            display: none;
        }
    }

    .tab-results {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        @media (--to-medium-screen-w) {
            max-height: 0;

            border-right: 1px solid $color-tint-1;
            border-left: 1px solid $color-tint-1;

            transition: max-height $base-transition-speed $base-transition-easing, border $base-transition-speed $base-transition-easing;
        }

        @media (--from-medium-screen-w) {
            flex-direction: row;
        }

        &.is-active {
            @media (--to-medium-screen-w) {
                max-height: 600px;
            }
        }
    }

    .tab-link,
    .tab-current {
        @extend .uppercase;

        display: block;
        width: 100%;
        padding: rem(14px) rem(16px);
        margin: 0;

        color: $color-primary;
        font-size: rem(16px);
        font-weight: $weight-regular;
        line-height: 1;
        text-align: left;

        transition: background $base-transition-speed $base-transition-easing;

        @media (--from-medium-screen-w) {
            width: auto;
            padding-top: rem(13px);
            padding-bottom: rem(8px);

            color: black;
            font-size: rem(20px);
        }

        &.is-disabled {
            color: black;

            pointer-events: none;
            opacity: 0.2;
        }
    }

    .tab-link {
        &:hover,
        &:focus,
        &.is-active {
            color: black;
            text-decoration: none;

            @media (--from-medium-screen-w) {
                color: white;

                background-color: $color-primary;
            }
        }

        &:focus {
            outline: none;
        }
    }

    .tab-current {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        color: white;

        background-color: $color-primary;

        @media (--from-medium-screen-w) {
            display: none;
        }

        &.is-active .icon {
            transform: rotate(180deg);
        }
    }
}
