/*
    Menu wrapper
*/
$nav-width: rem(30);

.menu-item-wrapper {
    display: none;

    @media (--from-normal-screen-w) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: $nav-width;
        position: fixed;
        top: 30%;
        // position it just inside the left-nav padding
        margin-left: rem(-80);
    }

    .menu-item {
        display: block;
        transform: rotate(180deg);
        width: rem(10);
        height: rem(10);
        margin-bottom: rem(20);
        border-radius: 50%;
        background-color: $color-light-grey;
        cursor: pointer;
        // no underline for anchor
        text-decoration: none;

        &-title {
            writing-mode: vertical-lr;
            font-weight: $weight-medium;
            margin: 0;
            margin-left: rem(10);
            display: none;
            cursor: pointer;
        }

        &.is-active,
        &:hover {
            border-radius: 0;
            background-color: transparent;
            width: auto;
            height: auto;
            color: $color-primary;

            .menu-item-title {
                display: block;
            }
        }

        &:hover:not(.is-active) {
            display: flex;
            align-items: flex-end;

            &::after {
                content: '';
                display: block;
                width: rem(10);
                height: rem(10);
                border-radius: 50%;
                background-color: $color-light-grey;
            }

            .menu-item-title {
                order: 1;
                margin-left: 0;
            }
        }
    }
}

// The actual elements to navigate to
.menu-item-locator {
    margin-top: rem(-120);
    padding-top: rem(120);
}