/*
    Input
*/
.input {
    width: 100%;
    height: rem(40);
    max-width: 100%;

    color: $color-secondary;
    font-family: $font-primary;
    font-size: rem(18);

    background-color: $color-light;
    border: 1px solid $color-tint-1;
    border-radius: rem(4);
    padding: rem(5) rem(10);

    appearance: none;


    // States
    &:focus {
        border-color: $color-secondary;
        box-shadow: 0 rem(2) rem(10) 0 $color-light;
        outline: 0;
    }

    &:disabled,
    &.is-disabled {
        cursor: not-allowed;
        background: $color-tint-2;
    }

    &.is-invalid {
        border-color: $color-error;
    }


    // Elements
    &::placeholder {
        color: $color-tint;
    }
}
