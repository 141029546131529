/*
    Text Content Block
*/
%quote {
    margin-top: 0;
        
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2em;

    @media (--from-small-screen-w)  {
        font-size: 28px;
    }

    @media (--from-medium-screen-w) {
        font-size: 32px;
    }

    &:before {
        display: inline-block;
        width: 0.25em;
        height: 0.2em;
        margin-right: 0.4em;

        background: none;
        border-style: solid;
        border-width: 0.3em 0 0.5em 0.3em;
        border-top-left-radius: 0.6em;
        content: '';
        box-sizing: content-box;
    }

    &::after {
        content: '\2019';
    }
}

.text-content-block {
    // default margins
    @extend %content-block-section;
    
    // default content block styling
    &.default {
        display: flex;
        flex-direction: column-reverse;
        @media (--from-normal-screen-w) {
            flex-direction: row;
            align-items: flex-start;
        }
    
        blockquote {
            padding-left: rem(20);
            margin-top: rem(20);
            margin-bottom: rem(30);
            font-style: italic;
            border-left: rem(2) solid $color-tint-1;
        }
    
        .content-primary {
            flex: 1;
            flex-basis: 100%;
            max-width: 100%;
            @media (--from-normal-screen-w) {
                padding-right: rem(25);
                flex-basis: 75%;
                max-width: 75%;
            }
            
        }
    
        .content-aside{
            flex-basis: 100%;
            max-width: 100%;
            padding-top: rem(15);
            border-bottom: rem(1) solid $color-light-grey;
            margin-bottom: rem(30);
            @media (--from-small-screen-w) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @media (--from-normal-screen-w) {
                flex-basis: 25%;
                max-width: 25%;
                border-top: rem(1) solid $color-light-grey;
                border-bottom: none; 
            }
        }
    
        .content-quote{
            width: 100%;
            padding-top: 0;
            margin-bottom: rem(30);
            blockquote {
                padding-left: 0;
                margin-top: 0;
                margin-bottom: rem(30);
                border: none;
                font-style: normal;

                q {
                    @extend %quote;
                    font-weight: 700;
                    font-size: rem(22);
                    @media (--from-small-screen-w)  {
                        font-size: rem(26);
                    }
                }
            }
            cite {
                display: block;
                font-style: italic;
                margin-top: rem(10);
                font-size: rem(14);
        
                &:before {
                    content: '— ';
                }
            }
            @media (--from-small-screen-w) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @media (--from-normal-screen-w) {
                flex-basis: 25%;
                max-width: 25%;
            }
        }
    }

    // aex specific styling
    &.aex {
        @media (--from-medium-screen-w) {
            display: flex;
            justify-content: space-between;

            .content-primary {
                // 2 column text for base text content
                &.text-columns {
                    @extend %text-columns;
                }
                
                // Only set half width if there is an aside/quote
                &.text-aside {
                    width: calc(50% - $aex-gutter);
                }
            }
    
            .content-aside, .content-quote {
                width: $aex-aside-width;
                padding-left: $padding-width-sm;
            }

            .content-quote {
                align-self: flex-end;
            }
        }

        .pullout-quote {
            max-width: 440px;
            padding-top: 20px;
        
            border-top: 1px solid $aex-col-border;
        
            p,
            q {
                @extend %quote;
            }
        
            cite {
                display: block;
        
                font-size: 14px;
        
                &:before {
                    content: '— ';
                }
        
                @media (--from-medium-screen-w) {
                    margin-top: 40px;
                }
            }
        }
    }

    // Relationship
    + .button-block {

        // Elements
        .button-block-inner { // Can't use "&-" convention here as it includes the "+"
            padding-top: 0;
        }
    }

    figure {
        margin: rem(20) 0;

        img {
            // maintain aspect ratio
            height: auto;
            // ensure image doesn't overflow the viewport while respecting the defined width in text editor
            max-width: 100%;
        }

        &.align-right {
            @media (--from-medium-screen-w) {
                float: right;
                margin-left: rem(60);   
            }
        }
    
        &.align-left {
            @media (--from-medium-screen-w) {
                float: left;
                margin-right: rem(60px);   
            }
        }
    }

}
