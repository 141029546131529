.aex-virtual-tour {
    color: $color-white;
    font-weight: $weight-medium;

    background-color: $color-black;


    // Elements
    &-inner {
        @media (--to-medium-screen-w) {
            padding-top: rem(13px);
            padding-bottom: rem(22px);

            text-align: center;
        }

        @media (--from-medium-screen-w) {
            display: flex;
            align-items: center;

            padding-top: rem(12px);
            padding-bottom: rem(12px);
        }
    }

    &-icon {
        width: rem(27px);
        height: rem(27px);

        @media (--from-medium-screen-w) {
            margin-right: rem(20px);
        }
    }

    &-intro {
        @media (--to-medium-screen-w) {
            padding-top: rem(3px);
            padding-bottom: rem(14px);

            font-size: rem(18px);
        }

        @media (--from-medium-screen-w) {
            flex-grow: 1;

            font-size: rem(20px);
        }
    }

    &-cta {
        @media (--to-medium-screen-w) {
            height: rem(36px);
            padding: rem(10px) rem(14px);

            font-size: rem(14px);
        }
    }
}
