/*
    Modal
*/
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer-modal-active;

    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    overflow: hidden;

    background: color($color-tone alpha(70%));

    opacity: 1;

    &.is-active {
        visibility: visible;
    }

    .content {
        position: relative;
        width: rem(500);
        min-height: rem(260);
        background-color: $color-light;
        padding: rem(40);
        // Restrict height in case the contents are too tall
        max-height: 90vh;
        overflow-y: auto;
    }

    .content::-webkit-scrollbar {
        display: none;
    }

    .close-btn {
        position: absolute;
        top: rem(10);
        right: rem(10);
    }
}