.news-letter-sign-up {
    margin: rem(50);

    .newsletter-signup {
        max-width: rem(500);
        margin: auto;

        .privacy-container {
            margin: rem(8) 0;
        }
    }
}
