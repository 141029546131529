.filter-dropdown {
    width: 100%;
    position: relative;
    
    .dropdown {
        display: flex;
        justify-content: space-between;
        padding: rem(20);
        border-bottom: rem(1) solid $color-tint-1;

        .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            max-width: rem(400);
        }

        span {
            text-transform: lowercase;
            color: $color-tint;
        }
    }

    &.open > .dropdown {
        border-bottom: none;

        span {
            color: $color-tint;
        }

        // advanced filter dropdown icon
        .icon {
            transform: rotate(180deg);
        }
    }

    .icon {
        transition: transform 0.2s ease-in;
        flex-shrink: 0;
        color: $color-primary;
        font-weight: $weight-medium;
        cursor: pointer;
    }

    // only rotate the dropdown icon within button, not other icons
    &.open .button > .icon {
        transform: rotate(180deg);
    }

    .selected {
        color: black;
    }

    // Advanced filter in artwork grid
    &.advanced-filter {

        &.wide {
            position: static;

            .options {
                top: auto;
            }
        }
    }
}