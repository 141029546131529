/*
    Content Set
 */
.content-set {
    border-top: rem(1) solid $color-light;
    border-bottom: rem(1) solid $color-light;

    .tile-block-title {
        border-bottom: rem(1) solid $color-light-grey;
        padding-bottom: rem(10);
    }

    // aex specific overrides
    &.aex {
        .tile-block-title {
            text-transform: none;
        }

        .tile-artwork {
            .tile-artist {
                a {
                    color: $color-black;
                }
            }
        }

        // adjust tile thumbnail size to fit narrower page width on desktop view
        .tile {
            .hover-area {
                .square {
                    @media (--from-small-screen-w) {
                        height: $aex-image-height-square;
                        width: $aex-image-height-square;
                    }
                }
            }
        }
    }
}
