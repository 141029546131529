/*
    Headroom
 */
 .top-headroom {
    .headroom {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: $layer-header;
    
        transition: transform $headroom-transition;
    
    
        // States
        &--unfixed,
        &--pinned {
            transform: translateY(0);
        }
    
        &--unpinned {
            transform: translateY(-100%);
        }
    }

    // Hack: allow headroom to be hidden from anywhere
    &.hide {
        visibility: hidden;
    }
 }