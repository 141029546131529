/*
    Stackla Widget Block
*/
.stackla-widget-block {
    margin-bottom: rem(32);

    @media(--from-small-screen-w) {
        margin-bottom: rem(16);
    }
}
