/*
    Pagebreak-quote
*/
.pagebreak-quote {
    margin-bottom: $padding-height;

    .article-break {
        margin: 0;
    }

    .quote {
        margin-top: 0;

        font-size: rem(24px);
        font-weight: 500;
        line-height: 1.2em;

        @media (--from-small-screen-w)  {
            font-size: rem(28px);
        }

        @media (--from-medium-screen-w) {
            font-size: rem(32px);
        }

        &::before {
            display: inline-block;
            // override border-box to display the quote symbol properly
            box-sizing: content-box;
            width: 0.25em;
            height: 0.2em;
            margin-right: 0.4em;

            background: none;
            border-style: solid;
            border-width: 0.3em 0 0.5em 0.3em;
            border-top-left-radius: 0.6em;
            content: '';
        }

        &::after {
            content: '\2019';
        }
    }

    .citation {
        display: block;

        font-size: rem(14);

        &:before {
            content: '— ';
        }

        @media (--from-medium-screen-w) {
            margin-top: rem(40);
        }
    }

    // default style uses div with background image
    &.default {
        @media (--from-normal-screen-w) {
            width: 75%;
        }
    
        .article-break {
            padding-top: rem(90px);
            padding-right: rem(15px);
            padding-bottom: rem(20px);
            padding-left: rem(15px);
    
            background-position: center top;
            background-repeat: no-repeat;
            background-size: auto 90px;
            color: $color-white;
    
            @media (--from-tiny-screen-w) {
                padding: rem(30px) rem(40px);
        
                background-size: cover;
            }
        
            @media(--from-medium-screen-w) {
                padding-right: rem(70px);
                padding-left: rem(70px);
            }
        
            @media(--from-normal-screen-w) {
                padding-top: rem(50px);
                padding-right: 0;
                padding-bottom: rem(50px);
                padding-left: 15%;
            }
    
            @media print {
                padding: 0;
            }
    
            &.red {
                background-color: $color-primary;
            }
            
            &.black {
                background-color: $color-black;
            }
        }
    }

    // aex specific
    // uses img and absolute positioning for the quote
    &.aex {
        position: relative;
        overflow: hidden;
        
        .image {
            display: block;
            width: 100%;
            max-height: $aex-break-max-height;
    
            @media (--from-small-screen-w) {
                opacity: 0.5;
            }
        }
    
        .pagebreak-contents {
            position: relative;
            
            @media (--to-small-screen-w) {
                padding: rem(30);
            }
    
            @media (--from-small-screen-w) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            @media (--from-large-screen-w) {
                left: 10%;
                transform: translate(0, -50%);
            }
    
            .article-break {
                max-width: rem(640);
                padding-top: rem(20);
                border-top: 1px solid $aex-col-border;
                color: $color-white;
            }
        }
    }
}
