.explore-further-tags {
    // normal styling for event grouping tags
    @extend %text-tiny;
    @extend %unlist;
    @extend %highlight;
    @extend %uppercase;

    display: flex;
    flex-wrap: wrap;

    line-height: 1;

    li {
        display: flex;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    // styling variation for event type tags
    &.event-type {
        li {
            margin-right: rem(5px);
            margin-bottom: 0;
            margin-left: 0;
        }
    
        a {
            @extend %text-small;
            
            height: rem(26px);
            padding: rem(3px) rem(5px);
    
            color: $color-black;
            font-weight: $weight-light;
    
            &:hover {
                color: $color-primary;
            }
        }

    }
}
