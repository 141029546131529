/*
    FAQ
 */
.faq {
    @extend %content-block-section;

    .faq-item {
        .item-header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: rem(18);
            padding-bottom: rem(18);
            margin-bottom: 0;
    
            color: $color-primary;
            font-weight: $weight-medium;
    
            cursor: pointer;
    
            .icon {
                flex-shrink: 0;
    
                @media print {
                    display: none;
                }
            }
    
            &.is-open .icon {
                transform: rotate(180deg);
            }
    
            .title {
                margin: 0;
                text-transform: none;
                font-weight: $weight-medium;
                // Ensure text is wrapped to not overflow the button
                white-space: normal;
                text-align: left;
            }
        }
    
        img {
            height: auto;
        }
    }

    &.default {
        @media (--from-normal-screen-w) {
            width: 75%;
        }
    
        .faq-item {
            border-bottom: 1px solid $color-tint-1;

            .item-header {
                .icon {
                    transition: transform 0.2s ease-in;
                }
            }

            .item-content {
                display: none;
        
                @media print {
                    display: block;
                }
        
                &.is-open {
                    display: block;
                }
            }
        
            &:first-child {
                border-top: 1px solid $color-tint-1;
            }
        }
    }

    // Aex specific styles
    &.aex {
        .faq-item {
            background: $color-tint-2;

            .item-header {
                padding: 16px 25px;
                margin: 0;
                background-color: $color-tint-2;
                transition: background-color 0.14s;

                &.is-open {
                    color: $aex-col-text-main;
                }

                .title {
                    color: inherit;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 1.3em;
                }

                @media (--to-medium-screen-w) {
                    .title {
                        font-size: 18px;
                    }
                }
            }

            & + .faq-item {
                margin-top: $padding-height;
            }

            .item-content {
                height: 0;
                padding: 0 25px 0;
                transform: scaleY(0%);
                transform-origin: top;
                transition: all 0.14s;
                
                &.is-open {
                    @extend %text-columns;
                    height: auto;
                    transform: scaleY(100%);
                    padding: 0 25px $aex-gutter;
                }
            }
        }
    }
}
