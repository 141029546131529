/*
    Button Block
 */
.button-block {
    margin-bottom: $padding-height-sm;

    @media (--from-tiny-screen-w) {
        margin-bottom: $padding-height-md;
    }
}
