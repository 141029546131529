/*
    Hamburger Icon
 */
.hamburger-icon {
    position: relative;

    width: rem(26);
    height: rem(20);

    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out;

    // States
    &.is-active {
        span {
            // Order
            &:nth-child(1),
            &:nth-child(4) {
                top: rem(14);
                left: 50%;
    
                width: 0%;
    
                opacity: 0;
            }
    
            &:nth-child(2) {
                transform: rotate(45deg);
            }
    
            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }


    // Icon
    span {
        position: absolute;
        left: 0;

        display: block;
        width: 100%;
        height: rem(2);

        background-color: currentColor;
        border-radius: rem(0);
        opacity: 1;

        transform: rotate(0deg);
        transition:
            top 0.25s ease-in-out,
            transform 0.25s ease-in-out,
            opacity 0.2s ease-in-out,
            background-color 0.2s ease-in-out;

        // Order
        &:nth-child(1) {
            top: rem(0);
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: rem(8);
        }

        &:nth-child(4) {
            top: rem(16);
        }
    }
}
