/*
    Header
*/
.header {
    word-break: normal;

    background-color: $color-light;
    border-bottom: rem(1) solid $color-tint-1;

    height: $header-height-sm;
    z-index: $layer-header;

    @media (--from-navigation-breakpoint) {
        height: $header-height;
    }

    // Elements
    &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding-top: rem(5);
        padding-bottom: rem(5);

        @media (--from-navigation-breakpoint) {
            justify-content: center;
            padding-top: rem(25);
            padding-bottom: rem(25);
        }
    }

    &-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        order: 2;
        @media (--from-logo-moving-point) {
            transform: translateX(-rem(100));
        }

        width: rem(180);
        height: auto;
        max-height: 100%;

        @media (--from-navigation-breakpoint) {
            order: 1;
            width: rem(270);
        }

        &:hover {
            color: $color-primary;
        }

        // Elements
        svg {
            width: 100%;
            max-height: 100%;
        }
    }

    .navigation-bar {
        flex: 1;
        order: 1;

        @media (--from-navigation-breakpoint) {
            order: 2;
        }
    }

}
