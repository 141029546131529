// artwork details
%item-detail-section {
    margin-top: rem(30);

    @media (--from-small-screen-w) {
        margin-top: rem(40);
    }
}

.archive-item-page {
    .item-details {
        margin-bottom: rem($vertical-spacing);
    }

    .item-details-body {
        display: flex;
        flex-wrap: wrap;
        
        .item-description {
            @extend %artwork-detail-section;
            width: 100%;
    
            @media (--from-medium-screen-w) {
                width: 50%;
                padding-right: $padding-width;
            }
    
            @media (--from-normal-screen-w) {
                width: 66.67%;
            }
        }
        
        .item-collection {
            @extend %artwork-detail-section;
            width: 100%;
            display: none;
            
            @media (--from-medium-screen-w) {
                display: block;
                width: 50%;
            }
    
            @media (--from-normal-screen-w) {
                width: 33.33%;
            }

            // Archive tile
            .tile-archive {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .heading {
                    flex-basis: 100%;
                    border-bottom: 0;
                }
                
                &-thumbnail {
                    position: relative;

                    &-icon {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: $layer-position-icon;
                        display: block;
                        background-color: $color-red;
                        width: rem(30);
                        height: rem(30);
                        background-position: center;
                        background-repeat: no-repeat;

                        @media (--from-small-screen-w) {
                            width: rem(40);
                            height: rem(40);
                        }
                    }

                    picture {
                        max-width: rem(100px);
                
                        @media (--from-medium-screen-w) {
                            max-width: rem(165px);
                        }
                    }

                    img {
                        display: block;
                    }
                }
            }
        }
    }
}
