// For both events and exhibitions
%event-section {
    margin-top: rem(30);

    @media (--from-small-screen-w) {
        margin-top: rem(40);
    }
}

.event-page {
    .event-details {
        margin-bottom: rem($vertical-spacing);
    }

    .event-details-body {
        display: flex;
        flex-wrap: wrap;
    }

    .event-description {
        @extend %event-section;

        width: 100%;

        @media (--from-small-screen-w) {
            width: 50%;
            padding-right: $padding-width;
        }

        @media (--from-normal-screen-w) {
            width: 66.67%;
        }
    }
    
    .event-group-tags {
        @extend %event-section;

        width: 100%;
        
        @media (--from-small-screen-w) {
            width: 50%;
        }

        @media (--from-normal-screen-w) {
            width: 33.33%;
        }
    }

    // override default sponsors style
    .sponsor {
        .sponsor-tile {
        }
    }
}