/*
    Button Group
*/
.button-group {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
        margin-right: rem(10);

        &:last-child {
            margin-right: 0;
        }
    }

    &.left-align {
        justify-content: flex-start;
    }

    &.right-align {
        justify-content: flex-end;
    }
}