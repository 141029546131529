/*
    Heading
*/

// Common heading styles
.heading {
    border-bottom: rem(1) solid $color-light-grey;
    padding-bottom: rem(5);
    margin-bottom: 0;

    &.heading-margin {
        margin-bottom: $padding-height-sm;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
    margin-top: 0;
    margin-bottom: rem(20);

    font-family: $font-primary;
    font-weight: $weight-medium;
    line-height: $spacing-secondary;
}

h1,
.heading-1,
h2,
.heading-2 {
    font-weight: $weight-medium;
}


h1,
.heading-1 {
    color: $color-primary;

    font-size: rem(26);
    line-height: $spacing-primary;

    @media (--from-small-screen-w) {
        font-size: rem(32);
    }

    @media (--from-medium-screen-w) {
        font-size: rem(42);
    }

    &.inverted {
        padding-top: rem(16);
        padding-bottom: rem(16);
    }
}

h2,
.heading-2 {
    @extend %uppercase;

    font-size: rem(22);

    @media (--from-small-screen-w) {
        font-size: rem(26);
    }
}

h3,
.heading-3 {
    font-size: rem(22);
    font-weight: $weight-light;

    &.inverted {
        padding-top: rem(8);
        padding-bottom: rem(8);
    }
}

h4,
.heading-4 {
    @extend %uppercase;
    
    font-size: rem(16);
    font-weight: $weight-regular;

    @media (--from-medium-screen-w) {
        font-size: rem(20);
    }
}

h5,
.heading-5 {
    color: $color-primary;
    font-size: rem(16);
    font-weight: $weight-regular;

    @media (--from-medium-screen-w) {
        font-size: rem(18);
    }
}

h6,
.heading-6 {
    font-size: rem(16);
    font-weight: $weight-regular;

    @media (--from-small-screen-w) {
        font-size: rem(20);
    }
}

h1,
h3 {
    &.inverted {
        display: inline-block;
        padding-right: rem(8);
        padding-left: rem(8);

        color: $color-light;

        background-color: $color-primary;

        @media print {
            padding-right: 0;
            padding-left: 0;

            color: $color-primary;

            background-color: transparent;
        }
    }
}


// Transformation
%uppercase {
    text-transform: uppercase;
}

%lowercase {
    text-transform: lowercase;
}

%italic {
    font-style: italic;
}

.uppercase {
    @extend %uppercase;
}

.lowercase {
    @extend %lowercase;
}



// Alignment
.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}