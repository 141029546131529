.search-bar {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (--from-medium-screen-w) {
        flex-direction: row;
        border-bottom: rem(1) solid $color-tint-1;
    }

    .grow {
        flex: 1 1 60%;
        display: flex;

        label {
            display: flex;
            align-items: center;
            color: $color-primary;
            padding-left: rem(10);
        }

        input {
            width: 100%;
            height: auto;
            padding: rem(17) rem(10);
            padding-right: 0;
            border: none;

            @media (--from-medium-screen-w) {
                padding-left: rem(20);
            }
        }
    }

    .shrink {
        flex: 0 0 auto;
        padding: rem(10);
        border-top: rem(1) solid $color-tint-1;

        @media (--from-medium-screen-w) {
            border-top: none;
            border-left: rem(1) solid $color-tint-1;
        }

        button {
            width: 100%;
        }

        &.no-padding {
            padding: 0;
        }
    }
}