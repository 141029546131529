.checkbox input {
    display: none;
}

.checkbox input + *::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    min-width: 15px;
    min-height: 15px;
    border: rem(1) solid $color-tint-1;
    border-radius: rem(3);
    margin-right: rem(10);
}

.checkbox input[type="checkbox"]:checked + *::before {
    color: $color-white;
    text-align: center;
    background: url($folder-icon/on-display-icon.svg) center no-repeat $color-black;
    border-color: $color-black;
    
}

.checkbox span {
    color: $color-primary;
}