/*
    Full screen dialog
*/
.full-screen-dialog {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-light;
    z-index: $layer-full-screen;
    // display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: scroll;
}