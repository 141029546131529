.major-project {
    .major-project-header {
        margin-bottom: $padding-height-sm;
        @media (--from-medium-screen-w) {
            margin-bottom: $padding-height-md;
        }
    }

    .hero-banner {
        z-index: $layer-banner; // To go over new white background on travelling sidebar
        max-width: rem($constrain-width-lg);
        margin: auto;
        position: relative;
    
        [data-hero-image='mobile'] {
            display: none;
            width: 100%;
        }
    
        [data-hero-image='desktop'] {
            display: none;
            width: 100%;
    
            overflow: hidden;
            height: 0;
            padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    
        // Orientation switch
        @media only screen and (orientation: landscape) {
            [data-hero-image='desktop'] {
                display: block;
            }
        }
    
        @media only screen and (orientation: portrait) {
            [data-hero-image='mobile'] {
                display: block;
            }
        }
    }

    // override normal virtual tour
    .aex-virtual-tour {
        position: relative;
        max-width: rem($constrain-width-lg);
        margin: auto;
        z-index: $layer-banner;
    }

    // Override content block styles
    .content-blocks {
        padding-top: rem(50);

        section {
            margin-bottom: rem(50);

            @media (--from-normal-screen-w) {
                margin-bottom: rem(100);
            }

            // Heading
            .block-heading {
                font-weight: $weight-medium;
                margin-bottom: rem(50);
            }
        }

        // override default content set layout
        .content-set {
            .tile-block-inner {
                display: flex;
                flex-wrap: wrap;
            }

            // Force content set tile thumbnails to maintain full width for all screen sizes
            .tile {
                flex-direction: column;
                padding-top: rem($vertical-spacing-small);
                padding-bottom: rem($vertical-spacing-small);
                border-bottom: 0;
                padding: 0;
                flex-basis: rem(400);
                margin-right: 0;
                margin-bottom: rem(40);
                max-width: 100%;

                @media screen and (min-width: 880px) {
                    flex-basis: 30%;
                    margin-right: rem(40);
                    margin-bottom: rem(40);
                }

                @media screen and (min-width: 1280px) {
                    flex-basis: 26%;
                    margin-right: rem(80);
                    margin-bottom: rem(80);
                }

                &:nth-child(3n-3) {
                    margin-right: 0;
                }

                &:nth-child(3n-7) { 
                    padding-top: 0;

                    @media screen and (min-width: 880px) {
                        padding-top: rem(100);
                    }
                }

                .tile-thumbnail-link {
                    margin-bottom: rem(20);

                    @media (--from-small-screen-w) {
                        margin-bottom: rem(10);
                    }
                }

                .tile-thumbnail {
                    width: auto;
                    max-height: rem(500);
                    overflow: hidden;
                    position: relative;
                    
                    .hover-area {
                        width: 100%;
                        position: relative;
                        
                        .image-container {
                            width: 100%;
                            height: 100%;
                        }
            
                        &:after {
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            display: block;
                            background-color: $color-black;
                            content: '';
                            opacity: 0;
                            transition: opacity $base-transition-easing $base-transition-speed;
                        }
            
                        [data-hover-text] {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            display: none;
                            color: $color-white;
                            font-size: rem(16);
                            padding: rem(10);
                            border: 1px solid $color-white;
                            border-radius: rem(5);
                            text-transform: uppercase;
                            transform: translateX(-50%) translateY(-50%);
                            z-index: $layer-hover-text;
                        }
            
                        [data-hover-status] {
                            display: none;
                        }
            
                        &:hover {
                            &::after {
                                opacity: 0.7;
                            }
            
                            [data-hover-text] {
                                top: 50%;
                                display: block;
                                opacity: 1;
                            }
            
                            [data-hover-status] {
                                transform: translateY(0);
                            }
                        }
                    }
            
                    .tile-thumbnail-image {
                        width: 100%;
                        max-height: none;
                    }
                }

                .tile-details {
                    margin: 0;

                    // Hide content set elements that we don't want to show for major projects
                    .tile-show--normal {
                        display: none;
                    }

                    // Show certain content set elements only for major projects
                    .tile-show--mp {
                        display: block;
                    }

                    hr {
                        width: 100%;
                        margin-top: 0;
                        color: $color-black;
                        border: 0;
                        border-top: 1px solid;
                    
                        // Override base css to force hr to display
                        &:first-child {
                            display: block;
                        }
                    }

                    .tile-title {
                        margin-bottom: rem(8);
                        a {
                            font-size: rem(16);
                            color: $color-black;
                            font-weight: $weight-medium;
                            transition: all 0.2s ease;
                
                            &:hover {
                                color: $color-primary;
                            }
                        }
                    }

                    p {
                        font-size: rem(15);
                        margin-bottom: 0;
                    }
                }

                // Customise for each entity
                // TODO: fix initials
                &.tile-artist .tile-thumbnail {
                    min-height: rem(300);
    
                    .tile-initials {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                
                        color: $color-black;
                        font-size: em(30px);
                        font-weight: $weight-heavy;
                
                        transform: translate(-50%, -50%);
                
                        @media(--from-small-screen-w) {
                            font-size: em(48px);
                        }
                    }
                }
    
                &.tile-event {
                    .tile-details {
                        .tile-title a {
                            color: $color-primary;
            
                            &:hover {
                                color: $color-black;
                            }
                        }
                    }
    
                    // TODO: add css class to html
                    .dates {
                        font-weight: $weight-medium;
                    }
                }
    
                &.tile-product {
                    strike {
                        position: relative;
                
                        display: inline-block;
                        margin-right: 8px;
                
                        text-decoration: none;
                    }
                
                    strike:before {
                        position: absolute;
                        top: 10px;
                        left: -2px;
                
                        display: block;
                        width: calc(100% + 4px);
                        height: 2px;
                
                        background-color: rgb(208, 21, 51);
                        content: '';
                    }
                
                    .soldout {
                        span {
                            margin-left: 0.5em;
                
                            color: rgb(208, 21, 51);
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                    }
                }

                .tile-news-date {
                    font-weight: $weight-regular;
                }
            }
        }
    }
}
