/*
    Gallery
*/
.gallery-block {

    &.section {
        margin-bottom: $padding-height-sm;

        @media (--from-tiny-screen-w) {
            margin-bottom: $padding-height-md;
        }
    }

    @media (--from-medium-screen-w) {
        position: relative;
    }

    .slick-track{
        display: flex;
        flex-direction: row;
    }

    // Display all images
    .slick-list {
        @media (--from-medium-screen-w) {
            overflow: visible;
        }
    }

    // Handle image into the same layer
    .slider-item {
        height: 100%;
        display: flex;
        flex-direction: column;

        @media (--from-medium-screen-w) {
            height: 100%;
        }

        .content-container {
            margin-top: rem(30px);
            max-width: rem(260);

            @media (--from-medium-screen-w) {
                max-width: rem(300);
            }

            p:empty {
                display: none;
            }
        
            p:first-child > strong:first-child {
                display: block;
        
                &::after {
                    content: '';
                    display: block;
                    width: rem(300);
                    max-width: 100%;
                    border-bottom: 1px solid $color-black;
                    margin: rem(4) 0 rem(10);
                }
            }
        
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    // Image style
    .slider-item img {
        // Fix the height in image
        width: auto;
        height: rem(300);
        opacity: 0.2;
        object-fit: cover;

        @media (--from-medium-screen-w) {
            height: rem(500);
        }
    }
    .slick-active .slider-item img {
        opacity: 1;
        filter: grayscale(0%);
    }

    .slider-item p {
        margin-top: rem(25);
        margin-bottom: 0;
        opacity: 0.2;
    }

    .slick-active .slider-item p {
        opacity: 1;
    }

    // Slider
    .slick-slider {
        // Set position to default static and let the button below to display according to .gallery-block's width
        @media (--from-medium-screen-w) {
            position: static;
        }

        &:hover {
            button.slick-arrow {
                opacity: 1;
                visibility: visible
            }
        }

        button.slick-arrow {
            position: absolute;
            top: 40%;
            z-index: $layer-caption-carousel-slide;
            background-color: $color-light;
            border-radius: 50%;
            width: rem(30);
            height: rem(30);
            transition: all 0.1s;
            cursor: pointer;

            @media (--from-small-screen-w) {
                width: rem(40);
                height: rem(40);
            }

            @media (--from-medium-screen-w) {
                opacity: 0;
                visibility: hidden;
            }

            &.slick-prev {
                left: rem(20);
                transform: rotate(90deg);

                &:hover {
                    color: $color-secondary;
                    transform: translateX(rem(-5)) rotate(90deg);
                }
            }

            &.slick-next {
                right: rem(20);
                transform: rotate(-90deg);

                &:hover {
                    color: $color-secondary;
                    transform: translateX(rem(5)) rotate(-90deg);
                }
            }

            & > svg {
                width: rem(20);
                height: rem(20);
                transform: translateY(rem(3));

                @media (--from-medium-screen-w) {
                    width: rem(25);
                    height: rem(25);
                }
            }
        }
    }

    // Add padding for each image
    .slick-slide:not(:last-of-type) {
        padding-right: rem(15);
        @media (--from-medium-screen-w) {
            padding-right: rem(30);
        }
    }

    &.aex {
        // Mask the left overflow of the carousel to avoid clashing with sidebar
        .gallery-block-inner {
            position: relative;
            &:before {
                position: absolute;
                top: 0;
                right: 100%;
                z-index: $layer-gallery-mask;
    
                width: 100%;
                height: 100%;
    
                background: $color-white;
                content: '';
            }
        }
    }
}
