.btn-proceed {
    position: absolute;
    bottom: rem(50);
    left: 50%;
    z-index: $layer-button-proceed;
    cursor: pointer;

    width: rem(50);
    height: rem(50);
    margin-left: rem(-25);

    // svg image needs to be imported in jsx
    // background: url("#{$folder-image}/svgs/proceed.svg") center center no-repeat;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 48%;
    opacity: 1;

    transition: opacity 0.2s, bottom 0.2s;

    &:hover {
        bottom: rem(45);

        opacity: 0.7;
    }
}