/*
    Media Block
*/
.media-block {
    @extend %content-block-section;

    width: 100%;

    iframe {
        width: 100%;
    }

    // meida type specific styling
    .type-video {
        iframe {
            min-height: rem(180);
    
            @media (--from-tiny-screen-w) {
                min-height: rem(280);
            }
    
            @media (--from-medium-screen-w) {
                min-height: rem(480);
            }
        }
    }

    // For youtube video
    .type-link {
        position: relative;

        &::before {
            display: block;
            content: '';
            width: 100%;
            height: 0;
            padding-top: calc(9 / 16 * 100%); // Aspect ratio: 16:9
        }

        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }

    // aex media is 100% width
    &.default {
        @media (--from-medium-screen-w) {
            width: 75%;
        }
    }
}