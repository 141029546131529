/*
    Art & Ideas
*/
.art-idea-page {
    .text-content-block.default .content-primary.text-columns {
        max-width: 100%;
    }
}

.art-ideas-banner {
    .algorithm-wrapper {
        position: relative;

        img {
            display: block;
            max-width: 100%;
        }
    }

    .algorithm-left {
        display: flex;
        align-items: flex-end;
        padding: rem(25) rem(15) rem(10) rem(15);
        background-color: $color-secondary;

        @media (--from-normal-screen-w) {
            position: absolute;
            padding: 0 rem(40) rem(25) rem(40);
            top: 0;
            bottom: 0;
            background-color: transparent
        }
    }

    .text-block {
        font-size: rem(26);
        text-transform: uppercase;
        font-kerning : normal;
        font-weight : 700;
        line-height: 0.9;
        letter-spacing: rem(1.5);
        white-space: pre;
        margin-bottom: 0;

        @media (--from-normal-screen-w) {
            font-size: rem(64);
        }

        &.red {
            color: $color-primary
        }

        &.white {
            color: $color-light
        }
    }
}

.art-ideas-search-form {
    margin-bottom: rem(30);

    @media (--from-normal-screen-w) {
        margin-bottom: rem(40);
    }

    .dropdown {
        border-bottom: none;
    }

    .reference-filter {
        min-width: rem(200);
    }
}


.text-hidden {
    display: none;
}