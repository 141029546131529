/*
    RTE Content
 */

.rte-content {

    // Elements
    blockquote,
    q {
        margin: rem(55) 0 rem(35);

        @media (--from-normal-screen-w) {
            width: calc(100% + rem(168));
            margin: rem(75) 0 rem(55) rem(-84);
        }
    }

    // Special button alignment
    p + p a.button,
    p + a.button {
        margin-top: rem(10);
    }
}
