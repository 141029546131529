.donation-block {
    display: flex;
    margin-bottom: $vertical-spacing-medium;


    // Elements
    &-content {
        color: $color-white;

        @media (--from-small-screen-w) {
            flex-grow: 1;
            align-self: center;
        }


        // Elements
        &-inner {
            padding-top: rem(35);
            padding-right: $padding-height-sm;
            padding-bottom: rem(35);
            padding-left: $padding-height-sm;

            @media (--from-normal-screen-w) {
                padding-top: rem(120);
                padding-right: rem(100);
                padding-bottom: rem(120);
                padding-left: rem(120);
            }
        }

        h1,
        h5 {
            color: $color-white;
        }

        a:hover {
            color: $color-primary;
        }
    }

    &-aside {
        @media (--from-small-screen-w) {
            flex-basis: rem(345);
            flex-grow: 0;
            flex-shrink: 0;
        }

        @media (--from-normal-screen-w) {
            flex-basis: rem(450);
        }


        // Elements
        &-inner {
            padding-right: $padding-height-sm;
            padding-bottom: rem(70);
            padding-left: $padding-height-sm;
            display: grid;
            place-items: center;
            
            @media (--from-small-screen-w) {
                padding-top: rem(70);
            }

            @media (--from-normal-screen-w) {
                padding-top: rem(120);
                padding-right: rem(120);
                padding-bottom: rem(120);
            }
        }
    }

    &-box {
        padding: rem(50) rem(60);
        background-color: $color-white;
        max-width: rem(315);

        @media (--from-normal-screen-w) {
            padding-top: rem(50);
            padding-right: rem(60);
            padding-bottom: rem(50);
            padding-left: rem(60);
        }


        &-inner {
            text-align: center;


            >p {
                color: $color-primary;
            }

            &-btn {
                width: 100%;
                margin-bottom: rem(8);
            }

            &-input {
                position: relative;
                width: 100%;
                font-weight: 400;
                margin-bottom: rem(8);
            }

            &-textbox {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: inherit;
                text-align: center;
                background: 0 0;
                border: 0;
                -webkit-transition: none;
                transition: none;
                appearance: none;
            }

            &-decorator {
                position: relative;

                width: rem(100);

                text-align: left;

                &::before {
                    position: absolute;
                    right: rem(10);
                    bottom: rem(-2);
                    left: rem(10);

                    height: 1px;

                    background-color: currentColor;
                    content: '';
                }
            }

            &-btn {
                width: 100%;
            }

            .selected {
                color: $color-white;
                background-color: $color-black;

                &:hover {
                    color: $color-white;
                }
            }
        }

    }




    // for no-overlay
    &-no-overlay {
        &-row {
            display: flex;
            flex-direction: column;
        
            max-width: rem(315);
            margin: 0 auto;
            @media (--from-medium-screen-w) {
                flex-direction: row;
                max-width: none; 
                margin: 0;  
            }
        }

        &-content {

            color: $color-white;
            padding: rem(15);

            @media (--from-small-screen-w) {
                flex-grow: 1;
            }

            @media (--from-normal-screen-w) {
                padding-bottom: rem(45);
            }


            h1,
            h5 {
                color: $color-white;
            }

            a:hover {
                color: $color-primary;
            }
        }

        &-image {
            height: rem(250);
            background-position: center;
            background-size: cover;

            @media (--from-medium-screen-w) {
                height: auto;
                flex-grow: 1;
            }
        }

        &-aside {
            @media (--from-small-screen-w) {
                flex-basis: rem(250);
                flex-grow: 0;
                flex-shrink: 0;
            }

            @media (--from-normal-screen-w) {
                flex-basis: rem(320);
            }
        }
        &-box {
            padding: rem(50) rem(60);
            background-color: $color-white;

            @media (--from-medium-screen-w) {
                padding: rem(27) rem(40);
            } 
            @media (--from-normal-screen-w) {
                padding-top: rem(50);
                padding-right: rem(60);
                padding-bottom: rem(50);
                padding-left: rem(60);
            }
    
    
            &-inner {
                text-align: center;
    
                >p {
                    color: $color-primary;
                }
    
                &-btn {
                    width: 100%;
                    margin-bottom: rem(8);
                }
    
                &-input {
                    position: relative;
                    width: 100%;
                    font-weight: 400;
                    margin-bottom: rem(8);
                }
    
                &-textbox {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    color: inherit;
                    text-align: center;
                    background: 0 0;
                    border: 0;
                    -webkit-transition: none;
                    transition: none;
                    appearance: none;
                }
    
                &-decorator {
                    position: relative;
    
                    width: rem(100);
    
                    text-align: left;
    
                    &::before {
                        position: absolute;
                        right: rem(10);
                        bottom: rem(-2);
                        left: rem(10);
    
                        height: 1px;
    
                        background-color: currentColor;
                        content: '';
                    }
                }
    
                &-btn {
                    width: 100%;
                }
    
                .selected {
                    color: $color-white;
                    background-color: $color-black;
    
                    &:hover {
                        color: $color-white;
                    }
                }
            }
    
        }
    }

}


.overlay {
    flex-direction: column;


    background-position: center;
    background-size: cover;

    @media (--from-small-screen-w) {
        flex-direction: row;
    }

}


.no-overlay {
    flex-direction: column;
    background-color: $color-red;

    padding-bottom: rem(60);
    @media (--from-small-screen-w) {
        padding-top: rem(70);
        padding-right: rem(15);
        padding-left: rem(15);
        padding-bottom: rem(60);
    }

    @media (--from-normal-screen-w) {
        padding-right: rem(140);
        padding-left: rem(140);
        padding-bottom: rem(60);
        padding-top: rem(70);
    }
}