/*
    Footer
 */
 .footer {
    padding-top: rem(10);
    padding-bottom: rem(10);

    background-color: $color-light;

    @media (--from-small-screen-w) {
        padding-top: rem(30);
        padding-bottom: rem(30);
    }

    @media (--from-medium-screen-w) {
        position: fixed;
        bottom: 0;
        z-index: $layer-footer;

        width: 100%;
        height: $height-footer;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: rem(100);

        background-color: transparent;
    }

    .footer-information {
        display: none;
        position: relative;
        height: rem(420);
        background-color: $color-tint-1;
        padding: rem(20) 0;

        @media (--from-medium-screen-w) {
            display: block;
        }

        .map-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .information-section {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            height: 100%;

            .inner {
                display: flex;
                justify-content: flex-end;
                align-items: stretch;
                z-index: $layer-footer-inner;
            }
        }

        .info-card {
            width: rem(380);
            background-color: $color-light;
            margin: 0 rem(8);
            padding: rem(20);
            color: $color-dark;
            box-shadow: 0 2px 4px rgb(0 0 0 / 30%);

            &.promo {
                background-image: url($folder-image/promo/newsletter.jpg);
                background-position: right center;
                background-size: cover;
                color: $color-light;

                .link {
                    color: $color-light;
                }

                .button {
                    color: $color-light;

                    &:hover {
                        background-color: $color-light;
                        color: $color-primary;
                    }
                }
            }

            &-section,
            h2 {
                margin-bottom: rem(15);
            }

            &-section {
                p {
                    margin-bottom: 0;
                }
            }

            .link {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: fit-content;

                .icon {
                    display: inline-block;
                    margin-right: rem(10);
                }
            }
        }
    }
    
    .newsletter-signup-banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: rem(12);
        background-image: url($folder-image/promo/newsletter.jpg);
        background-position: right center;
        background-size: cover;
        color: $color-light;

        @media (--from-medium-screen-w) {
            display: none;
        }

        h4,
        p {
            margin-bottom: rem(10);
        }

        .button {
            color: $color-light;
        }
    }

    .footer-main {

        &-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: rem(15) 0;

            @media (--from-medium-screen-w) {
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
            }

            & > *:not(:last-child) {
                margin-bottom: rem(20);

                @media (--from-medium-screen-w) {
                    margin-bottom: 0;
                }
            }

            &:first-child {
                border-bottom: rem(1) solid $color-tint-1;
            }

            .mobile-only {
                display: block;

                @media (--from-medium-screen-w) {
                    display: none;
                }
            }
        }

        .logo {
            width: rem(200);
            height: auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .contact-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (--from-medium-screen-w) {
                display: none;
            }

            .link {
                display: block;
            }

            & > *:not(:last-child) {
                margin-bottom: rem(10);
            }
        }

        .social-links {
            display: flex;
            align-items: center;

            .link {
                display: inline-block;
                color: $color-dark;

                &:hover {
                    color: $color-primary;
                }

                &:not(:last-child) {
                    margin-right: rem(20);
                }
            }
        }

        .copyrights {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (--from-medium-screen-w) {
                flex-direction: row;
                padding: 0 rem(5);
            }

            & > * {
                display: inline-block;
                padding: 0 rem(5);

                &:not(:last-child) {
                    @media (--from-medium-screen-w) {
                        border-right: rem(1) solid $color-dark;
                    }
                }
            }

            .fine-text {
                font-size: rem(14);
                line-height: 1em;
            }

            .links {
                .link {
                    display: inline-block;
                    padding: 0 rem(5);
                }

                .link:not(:last-child) {
                    border-right: rem(1) solid $color-dark;
                }
            }
        }
    }
}
