/*
    Newsletter signup
 */
 .newsletter-signup-home {

    display: none;
    @media (--from-medium-screen-w) {
        display: block;
        background-image: url($folder-image/promo/newsletter.jpg);
        background-position: right center;
        background-size: cover;
        color: $color-light;
        height: rem(240);
    }

    &-left {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: rem(40);
        padding-right: rem(40);
        padding-bottom: rem(25);
        
        > h2 {
            margin-bottom: 0;
            font-size: rem(32);
            text-transform: none;
        }
        
        > p {
            margin-bottom: rem(10);
            font-size: rem(22);
        }
    }

    &-form {

        &-inner {
            max-width: rem(360);
            display: flex;
            justify-content: space-around;
            margin-top: rem(10);
            gap: 5%;
            height: rem(40);
            width: 100%;
       }
    }


    &-link {
        color:$color-white;
        margin-top: rem(20);
        &:hover {
            text-decoration: underline;
            color:$color-white;
        }
   }


    &-right {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        text-align: right;
        justify-content: right;
        padding-left: rem(40);
        padding-right: rem(40);
        padding-bottom: rem(25);

        > .text-block {
            font-size: rem(50);
            @media (--from-normal-screen-w) {
                font-size: rem(64);
            }
            text-transform: uppercase;
            font-kerning : normal;
            font-weight : 700;
            line-height: 0.9;
            letter-spacing: rem(1.5);
            white-space: pre;
            margin-bottom: 0;
        }

        > .black {
            color: $color-black;
        }

        > .white { 
            color: $color-white;
        }
    }
 }