.file-download-block {
    display: inline-flex;
    flex-direction: column;
    margin: rem(30) rem(40) rem(30) 0;

    .new-file-attachment {
        width: rem(280);
        height: rem(220);
        display: block;
        position: relative;
        overflow: hidden;
        padding: 0;
        border: none;
        margin-bottom: rem(20);
        cursor: pointer;

        &::before {
            position: absolute;
            content:"";
            top:0;
            left:0;
            bottom:0;
            right:0;
            background-color: $color-dark-grey;
            opacity: 0;
            visibility: hidden;
            transition: visibility 0s, opacity 0.1s linear;
        }

        .download-icon {
            position: absolute;
            right: rem(10);
            bottom: rem(10);
            fill: $color-white;
            background-color: $color-primary;
            width: rem(50);
            height: rem(50);
            border-radius: 50%;
            padding: rem(15);
            overflow: visible;

            > svg {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .preview-image {
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
        }

        a.button {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            color: white;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.1s linear;

            &:hover {
                color: white;
            }
        }

        &:hover {
            &::before {
                visibility: visible;
                opacity: 0.6;
            }

            a.button {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .detail {
        max-width: rem(280);
    
        .heading {
            color: $color-primary;
            margin-bottom: rem(10);
        }
    }
}