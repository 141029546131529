/*
    Page
 */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    position: relative;

    min-width: 320px;
    min-height: 100%;
}

body {
    width: 100%;
    padding: 0;
    margin: 0;
    min-height: 100vh;

    &.is-fixed {
        overflow: hidden;

        & > #root {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }
}

.base {
    transition: transform $overlay-transition-open;
    // allows page to scroll down to reveal the footer when there's not enough content
    overflow-x: hidden;

    &.is-navigation-active {
        // hack: ensure the base is fixed to the top
        // so that the headroom and mobile nav is displayed properly
        // this is needed because this element has css transform, children (i.e. headroom, mobile nav) with position: fixed will be anchored to this element instead of the viewport
        // this has the unwanted side effect of resetting the page scroll when the mobile nav is opened
        position: fixed;
        width: 100%;
        // allow mobile navigation to display outside .base
        overflow-x: visible;
        transform: translateX($width-left-nav);
    }
}

.page {
    position: relative;
    z-index: $layer-page;
    overflow: hidden;
    background-color: white;

    @media (--from-medium-screen-w) {
        margin-bottom: rem(660);
        border-bottom: rem(1) solid $color-tint-1;
    }
}

.content-page {
    padding-top: rem(30);
}
